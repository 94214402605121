import { Component, OnInit } from '@angular/core';
import { StatService } from 'src/app/Shared/Services/stat.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  totalOffersCount: any;
  pendingRequestsCount: any;
  pendingClientOffersCount: any;
  finalizedOffersCount: any;
  unreviewedRequestsCount: any;
  totalOffersCountByUser: any;
  adminPendingOffersCount: any;
  finalizedOffersCountByUser: any;
  adminRejectedOffersCount: any;
  monthlyOffersCount: any;
  offersCount: any;
  isAdmin: boolean = false;

  constructor(private statService: StatService) {
    this.isAdmin = localStorage.getItem('role') === 'Admin';
  }

  ngOnInit(): void {
    const userId = localStorage.getItem('id');

    this.fetchPendingRequestsCount();
    this.fetchPendingClientOffersCount();
    this.fetchFinalizedOffersCount();
    this.fetchMonthlyOffersCount();
    this.fetchOffersCount();

    if (userId) {
      this.fetchTotalOffersCount(userId);
      this.fetchUnreviewedRequestsCount(userId);
      this.fetchTotalOffersCountByUser(userId);
      this.fetchAdminPendingOffersCount(userId);
      this.fetchFinalizedOffersCountByUser(userId);
      this.fetchAdminRejectedOffersCount(userId);
    } else {
      console.error('User ID is not available');
    }
  }

  fetchPendingRequestsCount(): void {
    this.statService.getPendingRequestsCount().subscribe(res => {
      this.pendingRequestsCount = res;
    });
  }

  fetchPendingClientOffersCount(): void {
    this.statService.getPendingClientOffersCount().subscribe(res => {
      this.pendingClientOffersCount = res;
    });
  }

  fetchFinalizedOffersCount(): void {
    this.statService.getFinalizedOffersCount().subscribe(res => {
      this.finalizedOffersCount = res;
    });
  }

  fetchMonthlyOffersCount(): void {
    this.statService.getMonthlyOffersCount().subscribe(res => {
      this.monthlyOffersCount = res;
    });
  }

  fetchTotalOffersCount(userId: string): void {
    this.statService.getTotalOffersCount(userId).subscribe(res => {
      this.totalOffersCount = res;
    });
  }

  fetchUnreviewedRequestsCount(userId: string): void {
    this.statService.getUnreviewedRequestsCount(userId).subscribe(res => {
      this.unreviewedRequestsCount = res;
    });
  }

  fetchTotalOffersCountByUser(userId: string): void {
    this.statService.getTotalOffersCount(userId).subscribe(res => {
      this.totalOffersCountByUser = res;
    });
  }

  fetchAdminPendingOffersCount(userId: string): void {
    this.statService.getAdminPendingOffersCount(userId).subscribe(res => {
      this.adminPendingOffersCount = res;
    });
  }

  fetchFinalizedOffersCountByUser(userId: string): void {
    this.statService.getFinalizedOffersCountByUser(userId).subscribe(res => {
      this.finalizedOffersCountByUser = res;
    });
  }

  fetchAdminRejectedOffersCount(userId: string): void {
    this.statService.getAdminRejectedOffersCount(userId).subscribe(res => {
      this.adminRejectedOffersCount = res;
    });
  }

  fetchOffersCount(): void {
    this.statService.getOffersCount().subscribe({
      next: (res: any) => { // Adding 'any' type to resolve TS7006 error, consider defining a specific type
        this.offersCount = res;
      },
      error: (err) => console.error('Failed to fetch offers count:', err)
    });
  }
  // Add additional methods here as necessary
}
