<!-- Begin page -->
<div class="wrapper">

    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">MON PROFIL </h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <h4 class="mt-2">Modifier mes informations </h4>
                                    <p class="text-muted mb-4">Remplissez le formulaire ci-dessous afin de mettre à jour
                                        vos informations.</p>
                                    <form [formGroup]="myForm" (ngSubmit)="saveUser()">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label for="full-name" class="form-label">Nom Complet</label>
                                                    <input class="form-control" type="text"
                                                        placeholder="Entrez votre nom complet" id="full-name"
                                                        formControlName="name">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label for="birth-day" class="form-label">Date de naissance</label>
                                                    <input class="form-control" type="date" id="birth-day"
                                                        formControlName="birth_day">
                                                </div>
                                            </div>
                                        </div> <!-- end row -->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label for="email-address" class="form-label">Adresse Email</label>
                                                    <input class="form-control" type="email"
                                                        placeholder="Entrez votre email" id="email-address"
                                                        formControlName="email">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label for="phone" class="form-label">Téléphone</label>
                                                    <input class="form-control" type="text" placeholder="xx xxxx xxx"
                                                        id="phone" formControlName="phone">
                                                </div>
                                            </div>
                                        </div> <!-- end row -->
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="mb-3">
                                                    <label for="address" class="form-label">Adresse</label>
                                                    <input class="form-control" type="text"
                                                        placeholder="Entrez l'adresse complète" id="address"
                                                        formControlName="address">
                                                </div>
                                            </div>
                                        </div> <!-- end row -->
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label for="town-city" class="form-label">Ville</label>
                                                    <input class="form-control" type="text"
                                                        placeholder="Entrez le nom de votre ville" id="town-city"
                                                        formControlName="city">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label for="state" class="form-label">État</label>
                                                    <input class="form-control" type="text"
                                                        placeholder="Entrez votre état" id="state"
                                                        formControlName="state">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label for="zip-postal" class="form-label">Code Postal</label>
                                                    <input class="form-control" type="text"
                                                        placeholder="Entrez votre code postal" id="zip-postal"
                                                        formControlName="zip">
                                                </div>
                                            </div>
                                        </div> <!-- end row -->
                                        <div class="row mt-4">
                                            <div class="col-sm-6">
                                            </div> <!-- end col -->
                                            <div class="col-sm-6">
                                                <div class="text-sm-end">
                                                    <button type="submit" class="btn btn-primary">Enregistrer</button>
                                                </div>
                                            </div> <!-- end col -->
                                        </div> <!-- end row -->
                                    </form>
                                </div> <!-- end card-body-->
                            </div> <!-- end card-->
                        </div> <!-- end col -->
                    </div>
                </div> <!-- container -->
            </div> <!-- content -->
            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->
    </div>
    <!-- END wrapper -->
</div>