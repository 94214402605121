import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/Shared/Services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  Register: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.Register = this.fb.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      birth_day: [''],
      password: ['', [Validators.required, Validators.minLength(8)]],
      email: ['', [Validators.required, Validators.email]],
      address: [''],
      phone: ['', [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.minLength(8), Validators.maxLength(12)]], // Adjust regex as needed
      zip: [''],
      city: [''],
      passwordConfirm: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  saveUser(): void {
    if (this.Register.valid) {
      const password = this.Register.get('password')?.value;
      const passwordConfirm = this.Register.get('passwordConfirm')?.value;

      // Check if passwords match
      if (password !== passwordConfirm) {
        // If they don't match, show a toaster and return early
        this.toastr.error('Les mots de passe ne correspondent pas.');
        return;
      }

      // Proceed with the form submission if passwords match
      const data = this.Register.value;
      this.authService.adduser(data).subscribe({
        next: (res) => {
          this.router.navigate(['/Login']);
          this.toastr.success('Compte crée avec succès');
        },
        error: (err) => {
          this.toastr.error(err.error.error || 'Une erreur s\'est produite.');
        }
      });
    } else {
      this.toastr.error('Veuillez remplir tous les champs requis.');

      const phone = this.Register.get('phone');
      const password = this.Register.get('password');

      if (phone?.errors?.['pattern']) {
        this.toastr.error('Le numéro de téléphone est incorrect.');
      } else if (password?.errors?.['minlength']) {
        this.toastr.error('Le mot de passe doit contenir au moins 8 caractères.');
      } else {
        // Generic error for any other validation errors
        this.toastr.error('Veuillez remplir tous les champs requis.');
      }
    }
  }
}