<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">LISTE DES UTILISATEURS </h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-6">
                                        <select class="form-select" id="example-select" [(ngModel)]="selectedStatus"
                                            (ngModelChange)="filterUsers()">
                                            <option value="all">Tous les utilisateurs</option>
                                            <option value="active">Les utilisateurs actifs</option>
                                            <option value="inactive">Les utilisateurs inactifs</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Chercher..."
                                                [(ngModel)]="searchQuery">
                                            <button class="btn btn-dark" type="button"
                                                (click)="searchUsers()">Chercher</button>
                                        </div>


                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <div *ngIf="isLoading" class="spinner-grow" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <table *ngIf="!isLoading" class="table table-centered mb-0"
                                        style="margin-top: 30px;">
                                        <thead>
                                            <tr>
                                                <th>Nom et prénom</th>
                                                <th>Email</th>
                                                <th>Demandes en attente</th>
                                                <th>Demandes acceptés</th>
                                                <th>Statut</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="filteredUser.length === 0">
                                                <td colspan="8" class="text-center">Aucune donnée disponible pour le
                                                    moment.
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let user of filteredUser   | paginate: { itemsPerPage: 5,currentPage: p }; let i = index">
                                                <td>{{user.name}}</td>
                                                <td>{{user.email}}</td>
                                                <td>{{ user.offresEnAttente }}</td>
                                                <td>{{ user.offresAcceptees }}</td>
                                                <td>
                                                    <!-- Switch-->
                                                    <span class=" form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            [id]="'switch' + user._id"
                                                            [checked]="user.status === 'active'"
                                                            (change)="changeUserStatus(user)" />
                                                    </span>

                                                </td>
                                                <td class="table-action">
                                                    <a type="button" data-bs-toggle="modal"
                                                        data-bs-target="#danger-alert-modal" class="action-icon"> <i
                                                            class="mdi mdi-delete"
                                                            (click)="prepareDelete(user._id)"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div
                                        style="display: flex; justify-content: center; align-items: center;margin-top: 20px;">
                                        <div class="has-text-centered">
                                            <pagination-controls (pageChange)="p = $event"
                                                class="pagination-controls"></pagination-controls>
                                        </div>
                                    </div>
                                </div> <!-- end card-body-->
                            </div> <!-- end card-->
                        </div> <!-- end col -->
                    </div>

                </div> <!-- container -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->

    </div>
    <!-- END wrapper -->

    <div id="danger-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content modal-filled bg-danger">
                <div class="modal-body p-4">
                    <div class="text-center">
                        <i class="ri-close-circle-line h1"></i>
                        <h4 class="mt-2">Confirmer la suppression</h4>
                        <p class="mt-3">Êtes-vous sûr de vouloir supprimer cet élément ? Cette action est irréversible.
                        </p>
                        <button type="button" class="btn btn-light my-2" style="margin-right: 10px;"
                            (click)="confirmDelete()">Continuer</button>
                        <button type="button" class="btn btn-light my-2" data-bs-dismiss="modal">Annuler</button>
                    </div>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->