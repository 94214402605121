import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/Shared/Services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  user: any = {};
  myForm!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getUser();
  }

  initForm() {
    this.myForm = this.formBuilder.group({
      name: [''],
      birth_day: [''],
      email: [''],
      phone: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: ['']
    });
  }

  getUser() {
    const userId = localStorage.getItem('id');
    if (userId) {
      this.userService.getById(userId).subscribe((res) => {
        this.user = res || {};
        this.populateFormFields();
      });
    } else {
      console.error('User ID not found in local storage');
    }
  }

  populateFormFields() {
    this.myForm.patchValue(this.user);
  }

  saveUser() {
    let data = this.myForm.value;
    this.userService.updateUser(data, localStorage.getItem('id')).subscribe(
      (res) => {
        this.getUser();
        this.toastr.success('Compte mis à jour avec succès');
      },
      (err) => {
        this.toastr.error('Vérifier les champs');
      }
    );
  }

}
