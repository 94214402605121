import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Shared/Services/auth.service';
import { NotificationsService } from 'src/app/Shared/Services/notifications.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  notifications!: any[];
  notnum!: any;
  role!: string | null;
  id!: string | null;
  fullName!: string;

  constructor(private authService: AuthService, private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.getNotifNumber()
    this.role = localStorage.getItem('role')
    this.id = localStorage.getItem('id');
    this.fullName = localStorage.getItem("name")!;
  }

  get roleDisplayText(): string {
    return this.role === 'user' ? 'Utilisateur' : 'Administrateur';
  }

  getNotifNumber() {
    this.notificationsService.getNotificationsNumber(localStorage.getItem('id')).subscribe((res) => {
      this.notnum = res;
    });
  }
  getNotifs() {
    this.notificationsService.getNotifications(localStorage.getItem('id')).subscribe({
      next: (res) => {
        this.notifications = res;
      },
      error: (error) => {
        // The console.error line has been removed to prevent logging.
        // Here, notifications are set to an empty array to clear any previous notifications.
        this.notifications = [];
        // If you need to perform any other error handling that doesn't involve logging, do it here.
      }
    });
  }


  logout() {
    this.authService.logout();
  }



}
