import { Component, OnInit } from '@angular/core';
import { OfferService } from 'src/app/Shared/Services/offer.service';
import { UserService } from 'src/app/Shared/Services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {

  users: any[] = [];
  searchQuery: string = '';
  filteredUser: any[] = []; // Initialize as empty array
  p: number = 1;
  itemsPerPage: number = 5;
  selectedStatus = 'all';
  showModal: boolean = false;
  userIdToDelete: any;
  isLoading: boolean = true; // Initialize loading state to true

  constructor(private userService: UserService, private offerService: OfferService) { }

  ngOnInit(): void {
    this.fetchUsers();
  }

  fetchUsers() {
    this.userService.getUsers().subscribe((users) => {
      this.users = this.filterOutAdmins(users); // Use the utility function
      this.filteredUser = [...this.users];
      this.fetchOffersForUsers();
      this.searchUsers();
      this.isLoading = false; // Data loaded successfully
    }, error => console.error(error));
    this.isLoading = false; // Stop loading on error
  }


  fetchOffersForUsers() {
    this.users.forEach(user => {
      this.offerService.getNumberOffer(user._id, false).subscribe(nba => {
        user.offresEnAttente = nba;
      }, error => console.error(error));
      this.offerService.getNumberOffer(user._id, true).subscribe(nbna => {
        user.offresAcceptees = nbna;
      }, error => console.error(error));
    });
  }

  searchUsers(): void {
    if (this.searchQuery.trim() !== '') {
      this.filteredUser = this.users.filter(user =>
        (user.name && user.name.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(this.searchQuery.toLowerCase()))
      );
    } else {
      this.filteredUser = [...this.users]; // Reset to all users if search query is empty
    }
  }

  filterOutAdmins(users: any[]): any[] {
    // Replace 'any[]' with a more specific type if available
    return users.filter(user => user.role !== 'Admin');
  }

  prepareDelete(id: any) {
    this.userIdToDelete = id;
  }

  confirmDelete() {
    this.userService.deleteUser(this.userIdToDelete).subscribe({
      next: (res) => {
        const dismissButton = document.querySelector('[data-bs-dismiss="modal"]') as HTMLElement | null;
        if (dismissButton !== null) {
          dismissButton.click();
        }
        this.fetchUsers();
      },
      error: (error) => {
        console.error('Error deleting user:', error);
      }
    });
  }

  getDisplayedUsers(): any[] {
    const startIndex = (this.p - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredUser.slice(startIndex, endIndex);
  }
  filterUsers(): void {
    const updateUsers = (data: any[]) => {
      // Use the utility function to filter out admin accounts from the data
      this.users = this.filterOutAdmins(data);
      this.fetchOffersForUsers();
      this.searchUsers(); // Apply search filter to the newly updated users list
    };

    if (this.selectedStatus === 'all') {
      this.userService.getUsers().subscribe(updateUsers, (error: any) => {
        console.error('Error fetching users by status', error);
      });
    } else {
      this.userService.GetUserbyStatus(this.selectedStatus).subscribe(updateUsers, (error: any) => {
        console.error('Error fetching users by status', error);
      });
    }
  }


  changeUserStatus(item: any) {
    const data: any = {
      id: item._id,
      status: item.status === 'active' ? 'inactive' : 'active'
    };
    this.userService.setUserStatus(data).subscribe(
      (result) => {
        const index = this.users.findIndex(user => user._id === item._id);
        if (index !== -1) {
          this.users[index].status = data.status;
          const allUsersIndex = this.filteredUser.findIndex(user => user._id === item._id);
          if (allUsersIndex !== -1) {
            this.filteredUser[allUsersIndex].status = data.status;
          }
          console.log('Status updated successfully');
        }
      },
      (errors) => {
        console.error('Error updating status', errors);
      }
    );
  }
}
