<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">LISTE DES DEMANDE EN ATTENTE </h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-6">
                                        <select class="form-select" id="example-select">
                                            <option>Tous les équipements</option>
                                            <option>Écran</option>
                                            <option>Laptop</option>
                                            <option>Ordinateur de bureau</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Chercher..."
                                                [(ngModel)]="searchQuery">
                                            <button class="btn btn-dark" type="button"
                                                (click)="search()">Chercher</button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th>Réference</th>
                                            <th>Équipement</th>
                                            <th>Marque</th>
                                            <th>Modele</th>
                                            <th>Date de creation</th>
                                            <th>Prix suggéré</th>
                                            <th>Statut</th>
                                            <th>Etat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Afficher un message si le tableau est vide -->
                                        <tr *ngIf="noteval.length === 0">
                                            <td colspan="8" class="text-center">Aucune donnée disponible pour le moment.
                                            </td>
                                        </tr>
                                        <!-- Fin de l'affichage du message -->
                                        <tr
                                            *ngFor="let offer of noteval | paginate: { itemsPerPage: 5,currentPage: p }; let i = index">
                                            <td>
                                                <a (click)="redirectToDetails(offer.offre._id)"
                                                    href="javascript:void(0)">
                                                    PC#{{ offer.offre._id?.substring(18, 24) }}
                                                </a>
                                            </td>

                                            <td [ngSwitch]="offer.offre.type">
                                                <span *ngSwitchCase="'monitor'" class="badge bg-warning">Écran</span>
                                                <span *ngSwitchCase="'desktop'" class="badge bg-info">Ordinateur de
                                                    bureau</span>
                                                <span *ngSwitchDefault class="badge bg-primary">Laptop</span>
                                            </td>

                                            <td>{{offer.offre.brand}}</td>
                                            <td>{{offer.offre.model}}</td>
                                            <td>{{offer.offre.date}}</td>
                                            <td>{{offer.offre.price}}</td>

                                            <td>
                                                <div class="progress progress-sm">
                                                    <div *ngIf="offer.offre.evaluated === true"
                                                        class="progress-bar bg-success" role="progressbar"
                                                        style="width: 100%"></div>
                                                    <div *ngIf="offer.offre.evaluated === false"
                                                        class="progress-bar bg-secondary" role="progressbar"
                                                        style="width: 25%"></div>

                                                </div>
                                                {{offer.offre.status}}
                                            </td>
                                            <td>


                                                <div>
                                                    <i class="mdi mdi-circle text-info"
                                                        *ngIf="offer.offre.evaluated === false"></i>En cours
                                                </div>


                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <div
                                    style="display: flex; justify-content: center; align-items: center;margin-top: 20px;">
                                    <nav>
                                        <ul class="pagination pagination-rounded mb-0">
                                            <li class="page-item">
                                                <pagination-controls (pageChange)="p = $event"
                                                    class="pagination-controls"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div> <!-- end col -->
                </div>

            </div> <!-- container -->

        </div> <!-- content -->

        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->