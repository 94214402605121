import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OfferService } from 'src/app/Shared/Services/offer.service';

@Component({
  selector: 'app-request-managment',
  templateUrl: './request-managment.component.html',
  styleUrls: ['./request-managment.component.css']
})
export class RequestManagmentComponent implements OnInit {

  @ViewChild('deleteModal') deleteModal: ElementRef | any;


  offers: any;
  p: number = 1;
  currentItemId: any;
  offerIdToDelete: any;
  searchTerm: string = ''; // Add a property to store the search term


  constructor(private offerServices: OfferService, private router: Router) { }

  ngOnInit(): void {
    this.getAllOffres();
  }

  // Method to filter valuations based on search term
  filterValuationsBySearchTerm(): void {
    if (this.searchTerm.trim() === '') {
      // If the search term is empty, display all valuations
      this.getAllOffres();
    } else {
      // Filter valuations based on search term
      this.offers = this.offers.filter((offer: any) => {
        const searchTermLower = this.searchTerm.toLowerCase();
        const userNameMatches = offer.user_id.name.toLowerCase().includes(searchTermLower);
        const dateMatches = offer.date.toLowerCase().includes(searchTermLower);
        // Convert val.date to a JavaScript Date object
        const valDate = new Date(offer.date);

        // Check if val.date is a valid date and matches the search term
        const isDateValid = !isNaN(valDate.getTime()) && (valDate.toDateString().toLowerCase().includes(searchTermLower));

        return userNameMatches || dateMatches || isDateValid;
      });
    }
  }

  getAllOffres(): void {
    this.offerServices.getallOffers(false).subscribe({
      next: (data) => {
        this.offers = data;
      },
      error: (err) => console.error(err),
    });
  }

  prepareDelete(id: any) {
    this.offerIdToDelete = id;
  }
  // Method to confirm deletion of an offer
  confirmDelete() {
    this.offerServices.deleteOffer(this.offerIdToDelete).subscribe({
      next: (res) => {
        // Find the dismiss button of the modal using its data-bs-dismiss attribute
        const dismissButton = document.querySelector('[data-bs-dismiss="modal"]') as HTMLElement | null;

        // If the dismiss button is found, simulate a click on it to close the modal
        if (dismissButton !== null) {
          dismissButton.click();
        }

        // Refresh the offer list after successful deletion
        this.getAllOffres();

        // Optionally, if you have a specific method to close the modal (e.g., in Angular/Bootstrap 5), you can call it here
        // this.hideModal(); // Uncomment and implement hideModal if needed
      },
      error: (error) => {
        console.error('Error deleting offer:', error);
        // Handle the error scenario (e.g., displaying an error message to the user)
      }
    });
  }

  redirectToDetails(id: string) {
    // Save the ID in local storage
    localStorage.setItem('offerId', id);

    // Route to '/details'
    this.router.navigate(['/details']);
  }
}
