import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private http: HttpClient) { }

  getallOffers(status: any) {
    let data = this.http.get<any[]>(`${AppConfig.offerUrl}/all/${status}`);
    return data;
  }
  addOffer(offer: any) {
    return this.http.post<any>(`${AppConfig.offerUrl}/post`, offer);
  }
  getById(id: any) {
    return this.http.get<any>(`${AppConfig.offerUrl}/get/${id}`);
  }
  deleteOff(id: any) {
    return this.http.delete<any>(`${AppConfig.offerUrl}/delete/${id}`);
  }
  valOffer(val: any, id: any) {
    return this.http.post<any>(`${AppConfig.valUrl}/post/${id}`, val);
  }
  getValByOffer(id: any) {
    return this.http.get<any>(`${AppConfig.valUrl}/getByOffer/${id}`);

  }

  getOfferWithValuation(id: any, status: any) {
    return this.http.get<any>(`${AppConfig.valUrl}/getOfferWithValuation/${id}/${status}`);

  }
  getAllWithStatus(status: string) {
    return this.http.get<any>(
      `${AppConfig.offerUrl}/getByStatus/${status}`
    );
  }

  getMesOffres(id: any) {
    return this.http.get<any>(
      `${AppConfig.offerUrl}/my_offres/${id}`
    );
  }

  getValuationByOffer(id: any): Observable<any> {
    return this.http.get<any>(`${AppConfig.valUrl}/getByOffer/${id}`);
  }


  getNumberOffer(id: any, status: any) {
    return this.http.get<any>(`${AppConfig.offerUrl}/getOffresNumber/${id}/${status}`);
  }

  getOffreByDate(date: any) {
    return this.http.post<any>(`${AppConfig.offerUrl}/getOffreByDate`, date);
  }

  getrespondedvaluation(date: any) {
    return this.http.post<any>(
      `${AppConfig.valUrl}/getValutaionByDate`, date
    );
  }

  getOfferBydateUser(user_id: any, status: any, date: any) {
    return this.http.post<any>(
      `${AppConfig.offerUrl}/getOffreByDateUser/${user_id}/${status}`, date
    );
  }

  rejectOffer(response: any) {
    return this.http.post<any>(
      `${AppConfig.valUrl}/respondeToValuation`, response
    );
  }

  deleteOffer(id: any): Observable<any> {
    return this.http.delete<any>(`${AppConfig.offerUrl}/delete/${id}`);
  }

}
