<div id="app-root">
    <header class="header_Header header_White">
        <div class="header_Wrapper">
            <div class="header_NavWrapper">
                <nav class="header_Nav">
                    <ul class="header_Menu">
                        <li><a href="#about">Qui sommes nous</a></li>
                        <li><a data-bs-toggle="modal" data-bs-target="#exampleModal">Contactez-nous</a></li>
                        <li><a href="#faqs">FAQ</a></li>

                    </ul>
                    <ul class="header_MenuCta">
                        <li>
                            <a class="Btn Btn--TxtSm" target="_blank" rel="noreferrer"><img
                                    src="/assets/static/playstore-svgrepo-com.svg" alt="Android Icon"
                                    style="width: 20px; height: 20px;"> Télécharger l'application</a>

                        </li>
                        <li *ngIf="!isAuth"><a class="Btn Btn--TxtSm" routerLink="/Login">Me connecter</a></li>
                        <li *ngIf="isAuth"><a class="Btn Btn--TxtSm" routerLink="/dashboard">Mon compte</a></li>
                    </ul>

                </nav>
            </div>
        </div>
        <div class="wrapper header_MobileHeader">
            <div class="Relative"><a class="header_Logo" href="#"></a><button
                    class="header_Hmbg"><span></span><span></span></button>
            </div>
        </div>
    </header>
    <main>
        <div class="Page BgWhite Relative">
            <header class="Solution_Header Black">
                <div class="wrapper">
                    <div>
                        <div class="Solution_Image mb2"><span
                                style="box-sizing:border-box;display:block;overflow:hidden; background:none;opacity:1;border:0;margin:0;padding:0;position:relative">
                                <img src="assets/static/Logo-Swapify-3.png" style="width: 200px;">
                            </span>
                        </div>
                        <div class="lg10 hlg1">
                            <h1 class="Solution_Title t1 Center mb2 mbmd3"><span>Revendez Votre </span>
                                <span>Équipement IT.</span>
                            </h1>
                        </div>
                        <div class="md10 hmd1 lg8 hlg2"><span
                                class="Block Center Swile mb4 mbmd5 t3 Solution_TextGradientEm Solution_TextGradientAnim Solution_OneLineCentered">Obtenez
                                l'Offre La Plus Avantageuse Pour Votre Équipement !
                            </span>
                            <div class="grid-flex--justify gap-sm">
                                <div class="Button_Wrapper Button_DivGradient"><a
                                        class="Button_Container Btn Btn--Big Btn--Black" data-bs-toggle="modal"
                                        data-bs-target="#centermodal">Obtenir mon offre</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper" style="margin-top: 20px;">
                        <h2 class="t6 Medium Center mb2">Nous reprenons vos ordinateurs de bureau, laptops et écrans
                            usagés. </h2>
                    </div>
                </div>
            </header>
            <section class="SolutionCards_Component Black">
                <div class="wrapper">
                    <div class="grid-flex--justify">
                        <div class="grid-flex__item sm6 md4 lg4">
                            <article class="SolutionCards_Resource">
                                <div>
                                    <div class="SolutionCards_Resource__Img"><span
                                            style="box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:absolute;top:0;left:0;bottom:0;right:0"><img
                                                src="assets/images/Desktop.jpg"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover" />
                                        </span> </div>
                                    <div class="SolutionCards_Sep" aria-hidden="true"></div>
                                    <div class="SolutionCards_Resource__Content">
                                        <span class="Block Medium mb1 t4 Center"><strong>Revendez votre ordinateur de
                                                bureau.</strong>
                                        </span>
                                        <p class="t5 Medium mb-3 Center">
                                            Obtenez une offre compétitive pour votre ancien ordinateur de bureau.
                                            Simplifiez-vous la vie et recyclez avec responsabilité.
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="grid-flex__item sm6 md4 lg4">
                            <article class="SolutionCards_Resource">
                                <div>
                                    <div class="SolutionCards_Resource__Img"><span
                                            style="box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:absolute;top:0;left:0;bottom:0;right:0"><img
                                                src="assets/images/Laptop.jpg"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover" /></span>
                                    </div>
                                    <div class="SolutionCards_Sep" aria-hidden="true"></div>
                                    <div class="SolutionCards_Resource__Content">
                                        <span class="Block Medium mb1 t4 Center"> <strong>Échangez votre
                                                laptop.</strong>
                                        </span>
                                        <p class="t5 Medium mb-3 Center">
                                            Transformez votre ancien laptop en valeur. Proposez-le et découvrez notre
                                            offre de rachat avantageuse.
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="grid-flex__item sm6 md4 lg4">
                            <article class="SolutionCards_Resource">
                                <div>
                                    <div class="SolutionCards_Resource__Img"><span
                                            style="box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:absolute;top:0;left:0;bottom:0;right:0"><img
                                                src="assets/images/Monitor.jpg" decoding="async" data-nimg="fill"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover"
                                                loading="lazy" /></span></div>
                                    <div class="SolutionCards_Sep" aria-hidden="true"></div>
                                    <div class="SolutionCards_Resource__Content">
                                        <span class="Block Medium mb1 t4 Center"> <strong>Votre écran mérite une seconde
                                                vie.</strong>
                                        </span>
                                        <p class="t5 Medium mb-3 Center">
                                            Ne laissez pas votre ancien écran prendre la poussière. Nous le rachetons
                                            pour lui donner un nouveau départ.
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <section class="SolutionCompanySlider_Component Black">
                <div class="wrapper">
                    <h2 class="t6 Medium Center mb2"> Si vous cherchez une solution fiable pour la revente de matériel
                        informatique, pensez à
                        <span class="Solution_TextGradientEm"> Swapify </span>
                        , la plateforme qui accepte une variété de marques.
                    </h2>
                </div>
                <div class="SolutionCompanySlider_Slider slick-nooverflow">
                    <div class="SolutionCompanySlider_Flex">
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/sony.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:60px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/hp.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/acer.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/msi.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:60px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/dell.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:120px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/asus2.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/huawei.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/samsung.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/rog.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/toshiba.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                        <div class="SolutionCompanySlider_Slide"><span
                                style="box-sizing:border-box;display:inline-block;overflow:hidden;width:90px;height:30px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative"><img
                                    src="assets/brands/lenovo.png"
                                    style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" /></span>
                        </div>
                    </div>
                </div>
            </section>

            <section class="SolutionComputer_Component BgLight Black" id="about">
                <div class="wrapper">
                    <div class="undefined Center t2 mb2 mbmd9 md10 hmd1 lg10 hlg1">
                        <h2>Qui Sommes Nous ?</h2>
                        <span
                            class="Block t3 Swile mb1 mbmd2 cardLinkCardAnimation_Gradient cardLinkCardAnimation_TextGradientEm">Découvrez
                            Notre Vision du Recyclage Technologique </span>
                    </div>
                    <div class="Relative"><span aria-hidden="true" class="SolutionComputer_Placeholder__tFSEL mb4"
                            style="padding-bottom:77.49546279491832%"></span>

                        <p class="Center t6 Medium mb4">

                            Avec plus de quatre décennies d'expertise en réparation et maintenance, IT Remake, une
                            initiative récente du Groupe TUNELEC, est le créateur de Swapify, une plateforme innovante
                            qui révolutionne l'utilisation des produits IT en adoptant un modèle circulaire.
                        </p>
                        <p class="Center t6 Medium mb4">
                            Que vous possédiez un ordinateur portable, un PC de bureau, ou un écran qui n'est plus
                            utilisé, Swapify vous offre l'opportunité unique de leur donner une nouvelle vie. En
                            soumettant votre matériel informatique à notre programme de reprise, vous participez à une
                            initiative de consommation responsable tout en valorisant vos biens.
                        </p>
                        <p class="Center t6 Medium mb4">
                            Développée par nos experts et nos développeurs dévoués, la plateforme Swapify vise à
                            démocratiser et à encourager l'adoption d'une consommation circulaire, transformant nos
                            habitudes de consommation linéaires en pratiques durables.
                        </p>

                        <p class="Center t6 Medium mb4">L'époque du gaspillage est révolue. Chez Swapify, nous prônons
                            la réutilisation. En choisissant de revendre votre laptop, desktop ou écran, vous offrez une
                            seconde chance à vos appareils et permettez à d'autres utilisateurs de profiter
                            d'équipements de qualité à un coût réduit.</p>
                        <p class="Center t6 Medium mb4">
                            Une seconde vie pourra être donnée à votre PC et permettre à d’autres clients
                            d’en profiter.
                        </p>
                        <p class="Center t6 Medium mb4">Rejoignez la communauté Swapify et participez à la création d'un
                            écosystème où tout le monde est gagnant, favorisant un avenir où la technologie bénéficie à
                            tous de manière éthique et durable.</p>
                    </div>
                </div>
            </section>
            <section class="SolutionFeatures_Component BgLight Black">
                <div class="wrapper">
                    <div class="SolutionFeatures_TextGradient Center t2 mb3 mbmd6">
                        <h2>Revendez en Toute Simplicité</h2>
                        <span class="Block Swile t3 Center SolutionYes_TextGradient mb4 mbmd10"><em>Trois Étapes Vers
                                Votre Bon d'Achat SCOOP.</em></span>
                    </div>
                    <div class="grid-flex SolutionFeatures_Grid">
                        <div class="grid-flex__item md4 lg4">
                            <article class="SolutionFeatures_Feature">
                                <span class="Center Medium SolutionComputer_Number"><strong>1</strong></span>
                                <h3 class="t6 Medium Center mb2"><strong>Soumettez Votre Équipement</strong></h3>
                                <p class="p0 Center">Remplissez rapidement notre formulaire détaillé pour nous
                                    présenter
                                    votre ordinateur, laptop ou écran.</p>
                            </article>
                        </div>
                        <div class="grid-flex__item md4 lg4">
                            <article class="SolutionFeatures_Feature">
                                <span class="Center Medium SolutionComputer_Number"><strong>2</strong></span>
                                <h3 class="t6 Medium Center mb2"><strong>Recevez et Validez Votre Offre</strong>
                                </h3>
                                <p class="p0 Center">Nous vous envoyons une proposition de rachat compétitive. Si
                                    elle vous
                                    convient, confirmez pour procéder à la vente.</p>
                            </article>
                        </div>
                        <div class="grid-flex__item md4 lg4">
                            <article class="SolutionFeatures_Feature">
                                <span class="Center Medium SolutionComputer_Number"><strong>3</strong></span>
                                <h3 class="t6 Medium Center mb2"><strong>Programmez la Collecte ou le Dépôt
                                    </strong></h3>
                                <p class="p0 Center">Choisissez entre la collecte à domicile sans frais
                                    supplémentaires ou
                                    le dépôt chez un de nos partenaires agréés pour la remise de votre matériel.</p>
                            </article>
                        </div>
                        <div class="grid-flex--justify gap-sm"></div>
                    </div>
                </div>
            </section>
            <section class="SolutionFireworks_Component Relative BgBlack White">
                <div class="wrapper">
                    <h2 class="Swile t2 mb2 Center">Swapify.TN </h2>
                    <div class="sm10 md10 lg8 hsm1 hmd1 hlg2">
                        <p class="t3 Swile Center SolutionFireworks_TextGradient mb12"><em>Votre Plateforme de Reprise
                                Multi-Équipements.</em></p>
                    </div>
                    <div class="SolutionFireworks_Items Relative md12 lg10 hmd2 hlg1">
                        <span class="Block p0 mb2 mbmd3">

                            <p class="t6 Medium mb-3">
                                Vous cherchez à revendre un écran, un laptop ou un desktop ? Swapify.TN est là pour vous
                                !
                            </p>
                            <p class="t6 Medium mb-3">
                                Nous vous offrons une plateforme unique pour revendre votre matériel informatique, qu'il
                                s'agisse d'ordinateurs portables, de bureaux ou d'écrans, indépendamment de leur marque
                                - Dell, Acer, Apple, Asus, Lenovo, et bien plus.
                            </p>
                            <p class="t6 Medium mb-3">
                                Profitez d'une estimation rapide et sans engagement pour votre équipement, peu importe
                                son état: neuf, fonctionnel ou même défectueux. Que votre matériel présente des signes
                                d'usure visuelle ou qu'il soit en parfait état, notre offre de rachat s'adapte à chaque
                                situation.
                            </p>
                            <p class="t6 Medium mb-3">
                                Notre service s'étend à une large gamme de conditions et de configurations : des
                                appareils anciens, avec des composants hors service (disque dur, batterie, écran
                                fissuré) ou un système d'exploitation varié (Windows, Linux, MacOS). Peu importe le
                                processeur (Intel Core, AMD), chaque appareil est évalué avec soin pour garantir une
                                offre juste, reflétant le marché actuel.
                            </p>
                            <p class="t6 Medium mb-3">
                                Pour nous, chaque équipement mérite une seconde chance. Lorsque vous soumettez votre
                                matériel, décrivez-le en détail, y compris ses éventuels défauts, pour optimiser votre
                                estimation.
                            </p>
                            <p class="t6 Medium mb-3">
                                Estimez la valeur de votre équipement depuis le confort de votre domicile et, une fois
                                accepté, profitez d'un paiement rapide sous forme de bons d'achat SCOOP. Chez
                                Swapify.TN, redonnez vie à votre matériel informatique tout en bénéficiant d'une
                                expérience de revente simplifiée et avantageuse.
                            </p>

                        </span>
                    </div>

                </div>
            </section>
            <section class="SolutionCardsSimple_Component BgWhite Black">
                <div class="wrapper">
                    <div class="Swile Center mb6 mbmd14 SolutionCardsSimple_TextGradient">
                        <h2>Les bonus à<br /><em>la reprise Swapify</em></h2>
                    </div>
                    <div class="grid-flex--justify">
                        <div class="grid-flex__item sm6 md4 lg4">
                            <article class="SolutionCards_Resource">
                                <div>
                                    <div class="SolutionCards_Resource__Img"><span
                                            style="box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:absolute;top:0;left:0;bottom:0;right:0"><img
                                                src="assets/static/1673632010592expertbook.jpg"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover" />
                                        </span>
                                        <span class="SwileResources_Category Medium">100 DT</span>
                                    </div>
                                    <div class="SolutionCards_Sep" aria-hidden="true"></div>
                                    <div class="SolutionCards_Resource__Content"><span
                                            class="Block Medium mb1 t4 Center"> <strong>Le Bonus à la reprise ASUS
                                                PRO</strong></span>
                                        <p class="t5 Medium mb-3 Center">
                                            Pour tout achat d'un nouveau PC Asus Pro (Expertbook), vous bénéficiez d'un
                                            Bonus sur votre valeur de reprise de 100Dt
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="grid-flex__item sm6 md4 lg4">
                            <article class="SolutionCards_Resource">
                                <div>
                                    <div class="SolutionCards_Resource__Img"><span
                                            style="box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:absolute;top:0;left:0;bottom:0;right:0"><img
                                                src="assets/static/1673632080750pcgaming.jpg"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover" /></span>
                                        <span class="SwileResources_Category Medium">100 DT</span>
                                    </div>
                                    <div class="SolutionCards_Sep" aria-hidden="true"></div>
                                    <div class="SolutionCards_Resource__Content"><span
                                            class="Block Medium mb1 t4 Center"> <strong>Le Bonus à la reprise
                                                PC GAMING sur
                                                mesure</strong></span>
                                        <p class="t5 Medium mb-3 Center">
                                            Pour tout achat d'un nouveau PC Gamer Monté , vous bénéficiez d'un Bonus sur
                                            votre valeur de reprise de 100Dt
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="grid-flex__item sm6 md4 lg4">
                            <article class="SolutionCards_Resource">
                                <div>
                                    <div class="SolutionCards_Resource__Img"><span
                                            style="box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:absolute;top:0;left:0;bottom:0;right:0"><img
                                                src="assets/static/1692968675799Sw.png" decoding="async"
                                                data-nimg="fill"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover"
                                                loading="lazy" /></span>
                                        <span class="SwileResources_Category Medium">150 DT</span>
                                    </div>
                                    <div class="SolutionCards_Sep" aria-hidden="true"></div>
                                    <div class="SolutionCards_Resource__Content"><span
                                            class="Block Medium mb1 t4 Center"> <strong>Le BONUS MSI Back To
                                                SCHOOL</strong></span>
                                        <p class="t5 Medium mb-3 Center">
                                            Profitez du Bonus exclusif MSI lors de la reprise de votre laptop et
                                            profitez d'un bon additionnel de 150Dt
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <section class="SolutionCta_Section SolutionCta_Left BgWhite Black">
                <div class="wrapper">
                    <article class="SolutionCta_Component Relative Black">
                        <div class="grid-flex grid-flex--center">
                            <div class="grid-flex__item md6 lg6 hmd6 hlg6 White">
                                <div class="t3 mb1 Swile SolutionCta_TextGradient ">
                                    <h3>Entreprises, Renouvelez Votre Parc Informatique avec Swapify</h3>
                                </div>
                                <p class="Fade--60 t6 Regular mb6 White">transformez votre ancien matériel informatique
                                    en opportunité. <br>Contactez Swapify pour une reprise efficace de vos ordinateurs,
                                    laptops et écrans.
                                </p>
                                <div class="SolutionCta_Btns___I41_">
                                    <div><a class="Btn Btn--Medium Btn--White" data-bs-toggle="modal"
                                            data-bs-target="#exampleModal">Simplifiez
                                            votre mise à jour technologique avec Swapify.</a></div>
                                </div>
                            </div>
                        </div>
                        <div class="SolutionCta_Image">
                            <div><span
                                    style="box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:absolute;top:0;left:0;bottom:0;right:0">
                                    <img src="assets/static/entreprise.png"
                                        style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover" /></span>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section class="SolutionFaq_Component BgWhite Black" id="faqs">
                <div class="wrapper">
                    <div class="Swile Center mb4 mbmd12 SolutionFaq_TextGradient">

                        <h3>FAQs sur la Revente de <br /><em>Votre Matériel Informatique.</em></h3>
                    </div>
                    <div class="md10 lg10 hmd1 hlg1">
                        <article class="Relative SolutionFaq_Faq SolutionFaq_Active">
                            <button class="SolutionFaq_Button"></button>
                            <div class="SolutionFaq_Faq__Title">
                                <h3 class="t6 Medium" itemProp="name">Quel est le délai pour recevoir une offre de
                                    reprise pour mon matériel ?</h3>
                            </div>
                            <div class="p0 SolutionFaq_Faq__Content SolutionFaq_Active"><span class="Fade--60"
                                    itemProp="text">
                                    <p>Vous obtiendrez une offre pour votre écran, laptop ou desktop sous 48 heures
                                        ouvrables suivant votre demande. 😊
                                    </p>
                                </span></div>
                        </article>
                        <article class="Relative SolutionFaq_Faq SolutionFaq_Active">
                            <button class="SolutionFaq_Button"></button>
                            <div class="SolutionFaq_Faq__Title">
                                <h3 class="t6 Medium" itemProp="name">Combien de temps avant d'être payé ?</h3>
                            </div>
                            <div class="p0 SolutionFaq_Faq__Content SolutionFaq_Active"><span class="Fade--60"
                                    itemProp="text">
                                    <p>Vos bons d'achat vous seront remis sous 72 heures après la réception de votre
                                        équipement.👌
                                    </p>
                                </span></div>
                        </article>
                        <article class="Relative SolutionFaq_Faq SolutionFaq_Active">
                            <button class="SolutionFaq_Button"></button>
                            <div class="SolutionFaq_Faq__Title">
                                <h3 class="t6 Medium" itemProp="name">Qu'advient-il de mes données personnelles ?</h3>
                            </div>
                            <div class="p0 SolutionFaq_Faq__Content SolutionFaq_Active"><span class="Fade--60"
                                    itemProp="text">
                                    <p>Nous procédons à la suppression systématique de toutes vos données personnelles
                                        dès l'arrivée de votre équipement, assurant une protection totale de votre vie
                                        privée. 🙌</p>
                                </span></div>
                        </article>
                        <article class="Relative SolutionFaq_Faq SolutionFaq_Active">
                            <button class="SolutionFaq_Button"></button>
                            <div class="SolutionFaq_Faq__Title">
                                <h3 class="t6 Medium" itemProp="name">Que faites-vous de mon équipement ?</h3>
                            </div>
                            <div class="p0 SolutionFaq_Faq__Content SolutionFaq_Active"><span class="Fade--60"
                                    itemProp="text">
                                    <p>Nous réutilisons les composants en bon état pour prolonger la durée de vie
                                        d'autres équipements. Les appareils fonctionnels peuvent être remis à neuf et
                                        revendus, tandis que les pièces inutilisables sont recyclées de manière
                                        écoresponsable.
                                    </p>
                                </span></div>
                        </article>
                        <article class="Relative SolutionFaq_Faq SolutionFaq_Active">
                            <button class="SolutionFaq_Button"></button>
                            <div class="SolutionFaq_Faq__Title">
                                <h3 class="t6 Medium" itemProp="name">Qui rachète mon matériel informatique ?</h3>
                            </div>
                            <div class="p0 SolutionFaq_Faq__Content SolutionFaq_Active"><span class="Fade--60"
                                    itemProp="text">
                                    <p>Swapify, par l'intermédiaire de IT Remake, spécialiste en maintenance et
                                        reconditionnement, offre un rachat de votre écran, laptop ou desktop. Nous
                                        assurons une offre de reprise compétitive, suivie d'un audit technique et d'une
                                        suppression sécurisée de vos données personnelles.</p>
                                </span></div>
                        </article>
                    </div>
                </div>
            </section>
        </div>
    </main>

    <footer class="footer_Footer">
        <div class="wrapper">
            <div class="footer_Header Relative">
                <div>
                    <i class="footer_Icon__lg"></i>
                    <div class="footer_Title">
                        <div>
                            <h6 class="SemiBold"><span>Obtenez votre meilleure offre!</span></h6>
                            <ul class="footer_MainLinks">
                                <li class="p1"><a>Qui sommes nous</a></li>
                                <li class="p1"><a>Contactez-nous</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <p class="p1 mb1">Nos dernières tendances dans votre boîte mail.</p>
                    <a class="footer_Form">
                        <div><input type="firstname" name="firstname" placeholder="Prénom" class="" /></div>
                        <div><input type="email" name="email" placeholder="Adresse email" class="" /></div>
                        <div><button type="submit" class="Btn Btn--TxtSm Btn--White"><span class="footer_Desktop">Je
                                    m'inscris</span></button></div>
                    </a>
                </div>
            </div>
            <ul class="footer_Menu grid-uniform mbmd10">
                <li class="Relative grid mb5 mbmd0"><span class="Swile">À propos</span><button class="White">+</button>
                    <ul>
                        <li class="mb1"><a class="p1">Qui sommes nous</a></li>
                    </ul>
                </li>
                <li class="Relative grid mb5 mbmd0"><span class="Swile">Mon compte</span><button
                        class="White">+</button>
                    <ul>
                        <li class="mb1"><a class="p1">Me connecter</a></li>
                        <li class="mb1"><a class="p1">Nous contacter</a></li>
                    </ul>
                </li>
                <li class="Relative grid mb5 mbmd0"><span class="Swile">Resources</span><button class="White">+</button>
                    <ul>
                        <li class="mb1"><a class="p1">Témoignages clients</a></li>
                        <li class="mb1"><a class="p1">Obtenir une offre</a></li>
                    </ul>
                </li>
                <li class="Relative grid mb5 mbmd0"><span class="Swile">Applications</span><button
                        class="White">+</button>
                    <ul>
                        <li class="mb1"><a class="p1">Télécharger dans
                                Android</a></li>
                        <li class="mb1"><a class="p1">Télécharger dans IOS</a></li>
                    </ul>
                </li>
            </ul>
            <div class="footer_FooterFooter Relative">
                <div class="footer_Flex"><i class="footer_Logo"></i>
                    <div><button class="footer_LangSelector"><span>Français</span>
                            <ul>
                                <li><a>Português</a></li>
                                <li><a>English</a></li>
                            </ul>
                        </button></div>
                </div>
                <ul class="footer_Legal">
                    <li><span>Swapify ©2024</span></li>
                    <li><a>Conditions d'Utilisation</a></li>
                    <li><a>Politique de Confidentialité</a></li>
                    <li><a>Mentions Légales</a></li>
                    <li><button>Cookies</button></li>
                </ul>
                <ul class="footer_SocialMedias">
                    <li><a><svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.67 18c.734 0 1.33-.577 1.33-1.29V1.29C18 .576 17.404 0 16.67 0H1.33C.596 0 0 .577 0 1.29v15.42C0 17.424.596 18 1.33 18zM4.097 5.83h-.018c-.912 0-1.502-.624-1.502-1.404 0-.798.608-1.404 1.538-1.404s1.502.606 1.52 1.404c0 .78-.59 1.404-1.538 1.404zm5.582 9.238H6.961v-.18c.003-.552.01-2.289.014-4.004v-.54l.001-.267v-1.49A62.106 62.106 0 006.96 6.94H9.68l-.001 1.152.041-.063c.37-.549 1.02-1.28 2.41-1.28 1.788 0 3.13 1.162 3.13 3.658v4.66H12.54V10.72c0-1.093-.393-1.838-1.377-1.838-.751 0-1.199.502-1.395.988-.072.173-.09.416-.09.659v4.539zm-4.223 0H2.738V6.94h2.718v8.128z">
                                </path>
                            </svg></a></li>
                    <li><a><svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 9.055C18 4.055 13.97 0 9 0S0 4.054 0 9.055c0 4.52 3.291 8.266 7.594 8.945v-6.328H5.309V9.055h2.285V7.06c0-2.27 1.343-3.523 3.4-3.523.984 0 2.014.177 2.014.177v2.228h-1.135c-1.118 0-1.467.698-1.467 1.414v1.699h2.496l-.399 2.617h-2.097V18C14.71 17.32 18 13.575 18 9.055">
                                </path>
                            </svg></a></li>
                    <li><a><svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 9c0 4.973-4.027 9-9 9s-9-4.027-9-9 4.027-9 9-9 9 4.027 9 9zM7.353 13.748c3.992 0 6.174-3.308 6.174-6.174 0-.095 0-.19-.004-.28a4.446 4.446 0 001.084-1.124 4.404 4.404 0 01-1.246.341c.45-.27.791-.692.954-1.201a4.394 4.394 0 01-1.377.527 2.17 2.17 0 00-3.694 1.98 6.156 6.156 0 01-4.474-2.269c-.184.32-.292.694-.292 1.09 0 .751.382 1.417.967 1.804a2.132 2.132 0 01-.981-.27v.027c0 1.053.747 1.926 1.741 2.129a2.163 2.163 0 01-.98.036A2.167 2.167 0 007.25 11.87a4.362 4.362 0 01-3.213.896c.949.62 2.092.98 3.316.98">
                                </path>
                            </svg></a></li>
                    <li><a><svg viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.175 0h.095l.241.002c1.42.01 5.376.068 6.522.378.774.21 1.384.827 1.59 1.61.338 1.274.373 3.788.377 4.295v.18c-.004.506-.039 3.02-.376 4.294a2.268 2.268 0 01-1.591 1.61c-1.22.33-5.625.374-6.763.38h-.54c-.587-.003-2.04-.016-3.478-.073l-.506-.022c-1.171-.056-2.262-.144-2.78-.285a2.268 2.268 0 01-1.59-1.61C.038 9.485.003 6.971 0 6.465v-.18c.003-.507.038-3.02.376-4.295.207-.783.816-1.4 1.59-1.61C3.113.07 7.07.012 8.489.002L8.73 0h.094zM7.2 3.642v5.465l4.676-2.732L7.2 3.642z">
                                </path>
                            </svg></a></li>
                    <li><a><svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.527.003h.238l.62.002c.74.003 1.164.009 1.543.02l.187.007.595.025c.957.044 1.611.196 2.183.418.6.226 1.145.58 1.594 1.038.458.45.812.993 1.038 1.594.222.572.374 1.226.418 2.183.01.23.02.423.026.617l.007.195c.013.43.018.926.02 1.878l.001.473V10.02c-.004 1.538-.017 1.883-.054 2.69-.044.957-.196 1.611-.418 2.183a4.6 4.6 0 01-2.631 2.632c-.573.222-1.227.374-2.184.418-.806.037-1.152.05-2.69.053l-.737.001H7.98c-1.538-.004-1.883-.017-2.69-.054-.957-.044-1.611-.196-2.183-.418a4.41 4.41 0 01-1.594-1.038 4.41 4.41 0 01-1.038-1.594c-.222-.572-.374-1.226-.418-2.184l-.025-.594-.007-.187c-.011-.38-.017-.802-.02-1.543l-.002-.62V8.473v-.238l.002-.62A43.51 43.51 0 01.057 5.29c.044-.957.196-1.611.418-2.183a4.41 4.41 0 011.038-1.594A4.41 4.41 0 013.107.475C3.679.253 4.333.101 5.29.057A43.69 43.69 0 017.616.005l.62-.002h.237zm.01 1.621H8.463c-1.915.002-2.226.013-3.099.052-.877.04-1.353.187-1.67.31-.391.144-.745.374-1.035.673-.299.29-.529.644-.673 1.035-.123.317-.27.793-.31 1.67-.04.873-.05 1.184-.052 3.099v1.074c.002 1.915.012 2.226.052 3.099.04.877.187 1.353.31 1.67.144.391.374.745.673 1.035.29.299.644.529 1.035.673.317.123.793.27 1.67.31.797.036 1.125.048 2.634.051l.465.001H10.002c1.509-.004 1.837-.016 2.634-.052.877-.04 1.353-.187 1.67-.31a2.98 2.98 0 001.708-1.708c.123-.317.27-.793.31-1.67.036-.797.048-1.125.051-2.634l.001-.724V9 7.998c-.004-1.508-.016-1.837-.052-2.634-.04-.877-.187-1.353-.31-1.67a2.788 2.788 0 00-.673-1.035 2.788 2.788 0 00-1.035-.673c-.317-.123-.793-.27-1.67-.31-.873-.04-1.184-.05-3.099-.052zM9 4.38a4.62 4.62 0 110 9.24 4.62 4.62 0 010-9.24zM9 6a2.999 2.999 0 100 5.998 2.999 2.999 0 000-5.998zm4.803-2.883a1.08 1.08 0 110 2.159 1.08 1.08 0 010-2.16z">
                                </path>
                            </svg></a></li>
                </ul>
            </div>
        </div>
    </footer>
</div>


<!-- Modal -->
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="banner_logo"></div><img class="ot-bnr-logo" style="height: 30px;"
                    src="assets/static/logo-large.png" title="Company Logo" alt="Company Logo">
                <button style="margin-right: 10px;" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4 class="t6 Medium mb2">Vous souhaitez en savoir plus sur swapify ?</h4>
                <p class="t4 Medium mb2">Notre équipe commerciale est disponible au <strong><a
                            class="cardLinkCardAnimation_TextGradientEm" href="tel:0021624307647">24 307
                            647</a></strong> du
                    lundi au vendredi de 8:00h à 18h00 et sera ravie d’échanger sur vos besoins.
                </p>
            </div>
            <div class="modal-footer">
                <a type="button" class="Btn Btn--Black me-auto" href="mailto:contact@swapify.tn">Nous contacter par
                    email</a>
                <button type="button" class="Btn MagicSwileCard_Btn--Transparent"
                    data-bs-dismiss="modal">Fermer</button>
            </div>
        </div>
    </div>
</div>


<!-- Center modal -->
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="centermodal" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div class="banner_logo"><img class="ot-bnr-logo" style="height: 30px;"
                        src="assets/static/logo-large.png" title="Company Logo" alt="Company Logo"></div>
                <button style="margin-right: 10px;" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body" class="SolutionComputer_Component BgLight Black">
                <div class="undefined Center t2 mb2 mbmd9 md10 hmd1 lg10 hlg1">
                    <span
                        class="Block t3 Swile mb1 mbmd2 cardLinkCardAnimation_Gradient cardLinkCardAnimation_TextGradientEm">Commencez
                        le processus de reprise de votre équipement IT</span>
                </div>
                <div class="grid-flex SolutionFeatures_Grid">
                    <div class="grid-flex__item md4 lg4">
                        <div class=" Center"> <img style="max-width: 150px;margin-bottom: 20px;"
                                src="assets/icons/laptop_444929.png" />
                        </div>
                        <div class="Center SolutionFeatures_ButtonBlock"><a class="Btn Btn--Big Btn--Black"
                                data-bs-dismiss="modal" [routerLink]="isAuth ? '/laptop-wizard' : '/Login'">Vendre mon
                                laptop</a></div>
                    </div>
                    <div class="grid-flex__item md4 lg4">
                        <div class=" Center"> <img style="max-width: 150px;margin-bottom: 20px;"
                                src="assets/icons/pc-tower_4172764.png" />
                        </div>
                        <div class="Center SolutionFeatures_ButtonBlock"><a class="Btn Btn--Big Btn--Black"
                                data-bs-dismiss="modal" [routerLink]="isAuth ? '/desktop-wizard' : '/Login'">Vendre mon
                                ordinateur de bureau</a></div>
                    </div>
                    <div class="grid-flex__item md4 lg4">
                        <div class=" Center"> <img style="max-width: 150px;margin-bottom: 20px;"
                                src="assets/icons/television_2885418.png" />
                        </div>
                        <div class="Center SolutionFeatures_ButtonBlock"><a class="Btn Btn--Big Btn--Black"
                                data-bs-dismiss="modal" [routerLink]="isAuth ? '/monitor-wizard' : '/Login'">Vendre mon
                                écran</a></div>
                    </div>
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->