import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUsers() {
    let data = this.http.get<any>(`${AppConfig.userUrl}/`);
    return data;
  }
  deleteUser(id: any) {
    return this.http.delete<any>(`${AppConfig.userUrl}/${id}`);
  }
  getById(id: any) {
    return this.http.get<any>(`${AppConfig.userUrl}/${id}`);
  }
  setUserStatus(user: any) {
    return this.http.post<any>(`${AppConfig.userUrl}/setStatus`, user);
  }
  updateUser(user: any, id: any) {
    return this.http.post<any>(`${AppConfig.userUrl}/updateProfile/${id}`, user);
  }

  GetUserbyStatus(status: any) {
    return this.http.get<any>(`${AppConfig.userUrl}/getUsersByStatus/${status}`);
  }
}
