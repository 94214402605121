import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from 'src/app/Shared/Services/offer.service';

@Component({
  selector: 'app-monitor-wizard',
  templateUrl: './monitor-wizard.component.html',
  styleUrls: ['./monitor-wizard.component.css']
})
export class MonitorWizardComponent implements OnInit {

  // Step properties
  selectedBrand: string = '';
  selectedModel: string = '';
  screenSize: string = '';
  screenType: string = '';
  screenResolution: string = '';
  deadPixel: string = '';
  aestheticCondition: string = '';
  photo: File[] = []; // Assuming multiple photos can be uploaded
  desiredPrice: string = '';
  additionalInfo: string = '';
  email: string = '';
  phone: string = '';

  // Booleans to control visibility of custom inputs
  showCustomScreenSize: boolean = false;
  showOtherInput: boolean = false;
  showCustomResolution: boolean = false;

  // Property to store the offer data
  offerData: any; // You can define a more specific type if needed
  // Property to store the offer data
  myForm!: FormGroup; // Define myForm property here

  constructor(private offerService: OfferService, private toastr: ToastrService, private router: Router, private fb: FormBuilder) {

    // Initialize FormGroup
    this.myForm = this.fb.group({
      brand: ['', Validators.required], // Add Validators.required to make it required
      model: [''],
      monitor_size: [''],
      monitor_type: [''],
      monitor_resolution: [''],
      dead_pixel: [''],
      pc_status: [''],
      price: [''],
      email: [''],
      phone: [''],
      type: [''],
      photo: this.fb.array([]) // Initialize 'photo' as a FormArray
      // Add more form controls as needed
    });
  }
  ngOnInit(): void {
    // Refresh the page once to clear local storage
    this.refreshPage();
  }

  refreshPage(): void {
    if (window.localStorage) {
      if (!localStorage.getItem('reload')) {
        localStorage['reload'] = true;
        window.location.reload();
      } else {
        localStorage.removeItem('reload');
      }
    }
  }

  // Update functions for step properties
  updateSelectedBrand(brand: string) {
    this.selectedBrand = brand;
  }

  updateSelectedDeadPixel(deadPixel: string) {
    this.deadPixel = deadPixel;
  }

  updateSelectedAestheticCondition(aesthetic: string) {
    this.aestheticCondition = aesthetic;
  }

  updateScreenSize(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue === 'Autre option') {
      this.showCustomScreenSize = true;
    } else {
      this.showCustomScreenSize = false;
      this.screenSize = selectedValue;
    }
  }

  updateScreenType(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue === 'Autre option') {
      this.showOtherInput = true;
    } else {
      this.showOtherInput = false;
      this.screenType = selectedValue;
    }
  }

  updateScreenResolution(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue === 'Autre option') {
      this.showCustomResolution = true;
    } else {
      this.showCustomResolution = false;
      this.screenResolution = selectedValue;
    }
  }

  // Array properties
  brandsList = [
    'ACER',
    'AOC',
    'APPLE',
    'ASUS',
    'BENQ',
    'DELL',
    'DYSON',
    'FUJITSU',
    'GIGABYTE',
    'GOOGLE',
    'HP',
    'HUAWEI',
    'HONOR',
    'LENOVO',
    'LG',
    'MICROSOFT',
    'MSI',
    'PANASONIC',
    'PHILIPS',
    'RAZER',
    'SAMSUNG',
    'SONY',
    'TOSHIBA',
    'VIEWSONIC',
    'XIAOMI',
    'ALIENWARE',
    'RAZER',
    'THOMSON',
    'SAGER',
    'MICRO-STAR INTERNATIONAL (MSI)',
    'CLEVO',
    'ELUKTRONICS',
    'ROG (Republic of Gamers - ASUS sub-brand)',
    'PREDATOR (ACER sub-brand)',
    'OMEN (HP sub-brand)',
    'ALIENWARE (DELL sub-brand)',
    'LEGION (LENOVO sub-brand)',
    'AORUS (GIGABYTE sub-brand)',
    'ZOTAC',
    'SHARP',
    'NEC',
    'EIZO',
    'IIYAMA',
    'AUTRE', // Keep "Other" as an option for brands not listed
  ];

  screenSizesList = [
    '9"', '10"', '11"', '12"', '13"', '14"', '15"', '16"', '17"',
    '18"', '19"', '20"', '21"', '22"', '23"', '24"', '25"', '26"', '27"',
    '28"', '29"', '30"', '32"', '34"'];

  conditionList = ["Excellent Etat", "Bon état", "Etat correct", "Endommagé"];

  screenTypesList = [
    'Incurvé', 'Plat', 'LED', 'LCD', 'OLED', 'IPS', 'VA', 'TN', 'QLED',
    'AMOLED', 'HDR', 'Touchscreen', 'Retina Display'
  ];

  functionalConditionsList = ['OUI', 'NON'];

  screenResolutionsList = [
    '640x480 (VGA)',
    '800x600 (SVGA)',
    '1280x720 (HD / 720p)',
    '1920x1080 (Full HD / 1080p)',
    '2560x1440 (QHD / 1440p)',
    '3840x2160 (4K / 2160p)',
    '5120x2880 (5K)',
    '7680x4320 (8K / 4320p)',
    '2560x1080 (UW-FHD)',
    '3440x1440 (UW-QHD)',
    '3840x1600 (UW-QHD+)',
    '5120x2160 (UW-5K)'
  ];

  aestheticConditionsList = ["Excellent Etat", "Bon état", "Etat correct", "Endommagé"];
  // File upload handling
  myFiles: File[] = [];

  imagePreviews: string[] = []; // Store image previews

  async preprocessImages(): Promise<void> {
    // Loop through each selected image and preprocess the previews
    for (const file of this.photo) {
      // Get the preview for the current image
      const preview = await this.getFilePreview(file);

      // Push the processed preview to the array
      this.imagePreviews.push(preview);
    }
  }

  async getFilePreview(file: File): Promise<string> {
    // Create a FileReader instance
    const reader = new FileReader();

    // Create a promise to handle the file load
    return new Promise<string>((resolve, reject) => {
      // Event listener to handle the file load
      reader.onload = () => {
        // Resolve the data URL
        resolve(reader.result as string);
      };

      // Event listener to handle errors
      reader.onerror = (error) => {
        // Reject with the error message
        reject(error);
      };

      // Read the file as a data URL, triggering the `onload` event
      reader.readAsDataURL(file);
    });
  }

  // Update onFileSelected method
  async onFileSelected(event: any): Promise<void> {
    const files: FileList = event.target.files;

    // Clear existing files
    this.myFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.myFiles.push(file);
    }

    // Generate previews
    this.generateImagePreviews();
  }

  // Generate image previews
  async generateImagePreviews(): Promise<void> {
    this.imagePreviews = [];

    for (let i = 0; i < this.myFiles.length; i++) {
      const file = this.myFiles[i];
      const dataUrl = await this.getFilePreview(file);
      this.imagePreviews.push(dataUrl);
    }
  }

  // Add removeImage method to remove selected image
  removeImage(index: number): void {
    this.myFiles.splice(index, 1); // Remove file from myFiles array
    this.imagePreviews.splice(index, 1); // Remove corresponding preview
  }

  sendOffer() {
    let errorMessage = '';

    if (!this.selectedBrand) {
      errorMessage = 'Veuillez sélectionner une marque.';
    }

    if (errorMessage !== '') {
      this.toastr.error(errorMessage, 'Action requise !', {
        timeOut: 5000, // Dismiss after 5 seconds
        progressBar: true,
        progressAnimation: 'increasing',
        closeButton: true,
      });
      return;
    }

    const loggedUser = localStorage.getItem('id');
    // Assuming 'user_id' is the field name expected by your backend for the user ID
    const formData = new FormData();

    // Append all image files to 'formData' under the 'files' key
    for (let img of this.myFiles) {
      formData.append('files', img);
    }
    // Set other form fields
    formData.set("user_id", loggedUser ? loggedUser : ''); // Fallback to an empty string if 'loggedUser' is null
    formData.set("brand", this.selectedBrand);
    formData.set("model", this.selectedModel);
    formData.set("monitor_size", this.screenSize);
    formData.set("monitor_type", this.screenType);
    formData.set("monitor_resolution", this.screenResolution);
    formData.set("dead_pixel", this.deadPixel);
    formData.set("pc_status", this.aestheticCondition);
    formData.set("price", this.desiredPrice);
    formData.set("email", this.email);
    formData.set("phone", this.phone);
    // Set the type to "laptop"
    formData.set("type", "monitor"); // Add this line



    // Call the API through your offer service
    this.offerService.addOffer(formData).subscribe(
      (res) => {
        this.toastr.success('Demande crée avec succès');
        // Reset form or navigate away upon successful submission
        this.router.navigate(['/myrequests']);
        // Additional UI logic or routing as needed
      },
      (err) => {
        console.error(err);
        // Handle errors (e.g., display error messages)
      }
    );
  }



}