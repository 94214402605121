import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StatService {

  constructor(private http: HttpClient) { }

  getPendingRequestsCount() {
    return this.http.get<any>(`${AppConfig.statUrl}/nbrDemandeEnAttende/`);
  }

  getOffersCount() {
    return this.http.get<any>(`${AppConfig.statUrl}/nbrOffre/`);
  }

  getPendingClientOffersCount() {
    return this.http.get<any>(`${AppConfig.statUrl}/nbrOffreEnAttendeClient/`);
  }

  getFinalizedOffersCount() {
    return this.http.get<any>(`${AppConfig.statUrl}/nbrOffrefinalise/`);
  }

  // Stats for user
  getUnreviewedRequestsCount(userId: any) {
    return this.http.get<any>(`${AppConfig.statUrl}/nbrDemandeNonEvalue/${userId}`);
  }

  getTotalOffersCount(userId: any) {
    return this.http.get<any>(`${AppConfig.statUrl}/nbrOffreTotal/${userId}`);
  }

  getAdminPendingOffersCount(userId: any) {
    return this.http.get<any>(`${AppConfig.statUrl}/nbrOffreEnAttendeAdmin/${userId}`);
  }

  getFinalizedOffersCountByUser(userId: any) {
    return this.http.get<any>(`${AppConfig.statUrl}/nbrOffreFinalise/${userId}`);
  }

  getAdminRejectedOffersCount(userId: any) {
    return this.http.get<any>(`${AppConfig.statUrl}/offreRefuseParAdmin/${userId}`);
  }

  getMonthlyOffersCount() {
    return this.http.get<any>(`${AppConfig.offerUrl}/getOffresEachMonth`);
  }

}
