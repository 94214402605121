import { Component, OnInit } from '@angular/core';
import { BonusService } from 'src/app/Shared/Services/bonus.service';
import { AppConfig } from 'src/app/Shared/app.config';

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.css']
})
export class BonusComponent implements OnInit {
  listbonus: any;

  constructor(private bonusService: BonusService) { }

  ngOnInit(): void {
    this.getAll()
  }

  getAll() {
    this.bonusService.getall().subscribe(res => {
      this.listbonus = res;
      for (let i = 0; i < res.length; i++) {
        this.listbonus[i].image = AppConfig.imgBonussUrl + '/' + res[i].image;
      }
    })
  }


}
