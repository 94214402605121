import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OfferService } from 'src/app/Shared/Services/offer.service';
import { ValuationService } from 'src/app/Shared/Services/valuation.service';

@Component({
  selector: 'app-myrequest',
  templateUrl: './myrequest.component.html',
  styleUrls: ['./myrequest.component.css']
})
export class MyrequestComponent implements OnInit {
  offersVall: any[] = [];
  eval: any[] = [];
  noteval: any[] = [];
  userId: string = '';
  status: string = "true"; // or "false", depending on the status you want to fetch
  p: number = 1;
  searchQuery: string = '';
  selectedEquipment: string = '';

  constructor(private offerService: OfferService,
    private valuationServices: ValuationService, private router: Router) { }


  ngOnInit(): void {
    this.userId = localStorage.getItem('id') || ''; // Utilisez une chaîne vide comme valeur par défaut
    this.getOffers();
  }

  getOffers() {
    this.valuationServices.getByCustomerOffVall(this.userId).subscribe((res) => {
      this.offersVall = res;
      for (let i = 0; i < res.length; i++) {
        if (res[i].offre.evaluated == true)
          this.eval.push(res[i]);
        else
          this.noteval.push(res[i]);
      }

      for (let i = 0; i < this.offersVall.length; i++) {
        if (res[i].valuation[0] == undefined) res[i].valuation[0] = {
          bonus: {
            amount: "",
            name: ""
          },
          response: {
            status: ""
          },
          response_admin: {
            status: ""
          }
        }
        if (res[i].valuation[0].bonus == undefined) res[i].valuation[0].bonus = {
          amount: "",
          name: ""
        }
      }

      // Apply search filter
      this.filterOffers();
    },
      err => {
        console.error("Error fetching offers:", err);
        this.noteval = [];
      });
  }


  filterOffers(): void {
    // Reset the offersVall array to its original state
    this.offersVall = [...this.eval, ...this.noteval];

    if (this.selectedEquipment) {
      this.offersVall = this.offersVall.filter(offer =>
        offer.offre.type === this.selectedEquipment
      );
    }

    if (this.searchQuery.trim() !== '') {
      const lowerCaseSearchQuery = this.searchQuery.toLowerCase();
      this.offersVall = this.offersVall.filter(offer =>
        (offer.offre.brand && offer.offre.brand.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (offer.offre.model && offer.offre.model.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (offer.offre.date && offer.offre.date.toLowerCase().includes(lowerCaseSearchQuery))
      );
    }
  }




  search(): void {
    this.getOffers();
  }


  redirectToDetails(id: string) {
    // Save the ID in local storage
    localStorage.setItem('offerId', id);

    // Route to '/details'
    this.router.navigate(['/details']);
  }

}
