import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './Components/Public/home/home.component';
import { LoginComponent } from './Components/Public/login/login.component';
import { DashboardComponent } from './Components/Private/User/dashboard/dashboard.component';
import { BonusComponent } from './Components/Private/User/bonus/bonus.component';
import { MyoffersComponent } from './Components/Private/User/myoffers/myoffers.component';
import { MyrequestComponent } from './Components/Private/User/myrequest/myrequest.component';
import { DetailsComponent } from './Components/Private/User/details/details.component';
import { UsersComponent } from './Components/Private/Admin/users/users.component';
import { BonusManagmentComponent } from './Components/Private/Admin/bonus-managment/bonus-managment.component';
import { ComponentsComponent } from './Components/Private/Admin/components/components.component';
import { AddComponent } from './Components/Private/User/add/add.component';
import { LaptopWizardComponent } from './Components/Private/User/Wizard/laptop-wizard/laptop-wizard.component';
import { DesktopWizardComponent } from './Components/Private/User/Wizard/desktop-wizard/desktop-wizard.component';
import { MonitorWizardComponent } from './Components/Private/User/Wizard/monitor-wizard/monitor-wizard.component';
import { SettingsComponent } from './Components/Private/User/settings/settings.component';
import { RegisterComponent } from './Components/Public/register/register.component';
import { ResetpasswordComponent } from './Components/Public/resetpassword/resetpassword.component';
import { UpdatepasswordComponent } from './Components/Public/updatepassword/updatepassword.component';
import { NotFoundComponent } from './Components/Public/not-found/not-found.component';
import { RequestManagmentComponent } from './Components/Private/Admin/request-managment/request-managment.component';
import { OffersManagmentComponent } from './Components/Private/Admin/offers-managment/offers-managment.component';

const routes: Routes = [
  { path: '', component: HomeComponent },

  { path: 'Login', component: LoginComponent },
  { path: 'Register', component: RegisterComponent },
  { path: 'Reset', component: ResetpasswordComponent },
  { path: 'Update', component: UpdatepasswordComponent },

  { path: 'dashboard', component: DashboardComponent },
  { path: 'bonus', component: BonusComponent },
  { path: 'myoffers', component: MyoffersComponent },
  { path: 'myrequests', component: MyrequestComponent },
  { path: 'details', component: DetailsComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'add', component: AddComponent },

  { path: 'users', component: UsersComponent },
  { path: 'managebonus', component: BonusManagmentComponent },
  { path: 'components', component: ComponentsComponent },
  { path: 'requests', component: RequestManagmentComponent },
  { path: 'offers', component: OffersManagmentComponent },

  { path: 'laptop-wizard', component: LaptopWizardComponent },
  { path: 'desktop-wizard', component: DesktopWizardComponent },
  { path: 'monitor-wizard', component: MonitorWizardComponent },



  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
