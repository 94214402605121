import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'; // Import ActivatedRoute
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/Shared/Services/auth.service';

@Component({
  selector: 'app-updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.css']
})
export class UpdatepasswordComponent implements OnInit {

  isAuth = false;
  role = localStorage.getItem('role');
  Form!: FormGroup;
  token: any;
  expired: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private toast: ToastrService, private route: ActivatedRoute,

  ) {
    let formconrol = {
      email: new FormControl(),
      password: new FormControl(),
      passwordConfirm: ['', [
        , confirmPasswordValidator]]
    };

  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.token = params['_token'];
      if (!this.token) {
        this.expired = true; // Handle as per your application's logic
        return; // Exit the method to prevent making a call with an undefined token
      }
      let data = { token: this.token }
      this.authService.checkExpired(data, this.token).subscribe(res => {
      },
        err => {
          this.expired = true;
        })
    });
    this.Form = this.fb.group({
      password: ['', Validators.required,],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
    });

  }

  updatepass() {
    let data = this.Form.value;
    this.authService.resetPassword(data, this.token).subscribe({
      next: (res) => {
        this.router.navigate(['/login']);
        this.toast.success('Mot de passe modifié avec succès');
      },
      error: (err) => {
        // Attempt to extract the error message from the response
        let errorMessage = 'Une erreur inattendue s\'est produite.';
        if (err.error) {
          // Check if the error object contains the "Error" property
          errorMessage = err.error.Error || JSON.stringify(err.error);
        }
        this.toast.error(errorMessage);
      }
    });
  }

}
/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { 'passwordsNotMatching': true };



};
