import { AppConfig } from './../../../../Shared/app.config';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from 'src/app/Shared/Services/offer.service';
import { ValuationService } from 'src/app/Shared/Services/valuation.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake.vfs as any) = pdfFonts.pdfMake.vfs;



@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent {
  @ViewChild('closebutton') closebutton: ElementRef | any;
  @ViewChild('closebutton1') closebutton1: ElementRef | any;
  @Input() offer: any;
  valuation: any;
  role = localStorage.getItem('role');
  formulaire: FormGroup;
  arrayImg: any[] = [];
  offerIdToDelete: any;


  constructor(private offerService: OfferService,
    private valuationService: ValuationService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService) {

    let formconrols = {
      given_price: new FormControl(),
      comment: new FormControl()
    };
    this.formulaire = this.fb.group(formconrols);
  }

  getOfferDetails(): void {
    const offerId = localStorage.getItem('offerId');
    if (!offerId) {
      // Redirect to /dashboard route
      this.router.navigate(['/dashboard']);
      // Show a French toast message to the user
      this.toastr.error('Désolé, nous n\'avons pas pu trouver les détails de l\'offre. Veuillez réessayer plus tard.', 'Erreur');
      return;
    }

    this.offerService.getValuationByOffer(offerId).subscribe((res) => {
      if (res[0] !== undefined) {
        this.valuation = res[0];
      }
    });

    this.offerService.getById(offerId).subscribe((res) => {
      if (res) {
        this.offer = res;
        this.arrayImg = res.photo.map((photoUrl: string) => ({
          image: `${AppConfig.imgUrl}/${photoUrl}`,
          thumbImage: `${AppConfig.imgUrl}/${photoUrl}`
        }));
      }
    });
  }




  ngOnInit(): void {
    this.getOfferDetails();

  }


  getPropertyKeys(): string[] {
    const displayProperties = [
      'brand',
      'model',
      'monitor_size',
      'monitor_type',
      'monitor_resolution',
      'cpu',
      'cpu_gen',
      'ram',
      'storage_type',
      'storage_size',
      'gpu',
      'functional',
      'pc_status',
      'monitor_status',
      'battery_status',
      'charger_status',
      'charger_type',
      'keyboard_type',
      'keyboard_retro',
      'dead_pixel' // Added 'dead_pixel' property
    ];

    if (this.offer) {
      return displayProperties.filter(prop => this.offer.hasOwnProperty(prop));
    }
    return [];
  }

  getPropertyDisplayName(property: string): string {
    switch (property) {
      case 'battery_status':
        return 'État de la batterie';
      case 'charger_status':
        return 'État du chargeur';
      case 'charger_type':
        return 'Type de chargeur';
      case 'cpu':
        return 'Processeur';
      case 'cpu_gen':
        return 'Génération du processeur';
      case 'dead_pixel': // Added 'dead_pixel' case
        return 'Pixels morts';
      case 'functional':
        return 'Fonctionnel';
      case 'gpu':
        return 'Carte graphique';
      case 'keyboard_retro':
        return 'Clavier rétroéclairé';
      case 'keyboard_type':
        return 'Type de clavier';
      case 'model':
        return 'Modèle';
      case 'monitor_size':
        return 'Taille de l\'écran';
      case 'monitor_type':
        return 'Type d\'écran';
      case 'monitor_resolution':
        return 'Résolution de l\'écran';
      case 'monitor_status':
        return 'État de l\'écran';
      case 'pc_status':
        return 'État du PC';
      case 'ram':
        return 'Mémoire RAM';
      case 'storage_size':
        return 'Taille de stockage';
      case 'storage_type':
        return 'Type de stockage';
      case 'brand':
        return 'Marque';
      default:
        return '';
    }
  }




  Evaluate() {
    this.valuationService.valOffer(this.formulaire.value, this.offer._id).subscribe((res) => {
      this.getOfferDetails();
      this.closebutton.nativeElement.click();
      this.toastr.success('Offre évaluée avec succès');

    });
  }

  async showPdf() {
    const imageBase64 = await this.getBase64ImageFromURL("/assets/static/Logo-Swapify-3.png");
    let docDefinition = {
      content: [
        {
          text: 'Fiche produit' + ' ' + "#PC" + this.offer._id.substring(18, 24),
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [
              {
                text: "Société Scoop informatique", margin: [0, 0, 0, 3],
              },
              {
                text: 'Adresse email: ' + "scoop.info@gmail.com", margin: [0, 0, 0, 3],
              },
              {
                text: ' Adresse: ' + "Centre urbain 1006 Tunis", margin: [0, 0, 0, 3],
              },
              {
                text: ' Numéro de telephone:  ' + "71543266", margin: [0, 0, 0, 3],
              },
              {
                text: 'site web: ' + "www.scoop.com.tn",
                link: "https://www.scoop.com.tn/",
                color: 'blue',
              }
            ],
            [
              {
                image: imageBase64,
                width: 120,
                height: 120,
                alignment: 'right'
              }
            ]
          ]
        },


        {
          text: 'Informations client',
          style: 'header'
        },
        this.getUser(),



        {
          text: 'Informations de l\'article',
          style: 'header'
        },

        this.getProduct(),

        {
          text: 'Signature',
          style: 'sign'
        },
      ],
      info: {
        title: this.offer._id.substring(18, 24),
        author: this.offer.user_id.name,
        subject: 'Fiche produit',
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 20, 0, 10],
          decoration: 'underline'
        },
        name: {
          fontSize: 16,
          bold: true
        },
        jobTitle: {
          fontSize: 14,
          bold: true,
          italics: true
        },
        sign: {
          margin: [0, 20, 0, 10],
          alignment: 'right',
          italics: true
        },
        tableHeader: {
          bold: true,
        }
      }
    };
    pdfMake.createPdf(docDefinition as any).download("#PC" + this.offer._id.substring(18, 24));

  }
  getUser() {
    const exs: any = [];
    exs.push(
      [{
        columns: [
          [{
            text: "Nom et prénom : " + this.offer.user_id.name, margin: [0, 0, 0, 10],
            style: 'jobTitle'
          },
          {
            text: "Email : " + this.offer.user_id.email, margin: [0, 0, 0, 10],
          },
          {
            text: "Numéro de contact : " + this.offer.user_id.phone, margin: [0, 0, 0, 10],
          },
          {
            text: "Adresse : " + this.offer.user_id.address + ' ' + this.offer.user_id.zip + ' ' + this.offer.user_id.city,
          }],


        ]
      }]
    );

    return {
      table: {
        widths: ['*'],
        body: [
          ...exs
        ]
      }
      ,
      layout: 'noBorders'
    };
  }
  getProduct() {
    const exs: any = [];
    exs.push(
      [{
        columns: [
          [{
            text: "Réference : " + "#PC" + this.offer._id.substring(18, 24), margin: [0, 0, 0, 10],
            style: 'jobTitle'
          },
          {
            text: "Marque : " + this.offer.brand + this.offer.model, margin: [0, 0, 0, 10],
          },

          {
            text: "Etat fonctionnel : " + this.offer.functional, margin: [0, 0, 0, 10],
          },
          {
            text: "Processeur : " + this.offer.cpu, margin: [0, 0, 0, 10],
          },
          {
            text: "Génération : " + this.offer.cpu_gen, margin: [0, 0, 0, 10],
          }, {
            text: "Ram : " + this.offer.ram, margin: [0, 0, 0, 10],
          }, {
            text: "Stockage : " + this.offer.storage_type, margin: [0, 0, 0, 10],
          }, {
            text: "Carte graphique : " + this.offer.gpu, margin: [0, 0, 0, 10],
          }, {
            text: "Taille de l'écran : " + this.offer.monitor_size, margin: [0, 0, 0, 10],
          },
            , {
            text: "Etat de l'écran : " + this.offer.monitor_status, margin: [0, 0, 0, 10],
          }, {
            text: "Etat esthétique : " + this.offer.pc_status, margin: [0, 0, 0, 10],
          },
          {
            text: "Etat de la batterie : " + this.offer.battery_status, margin: [0, 0, 0, 10],
          }, {
            text: "Etat du chargeur : " + this.offer.charger_status, margin: [0, 0, 0, 10],
          },
          {
            text: "Type du clavier : " + this.offer.keyboard_type
          },




          ],


        ]
      }]
    );

    return {
      table: {
        widths: ['*'],
        body: [
          ...exs
        ]
      }
      ,
      layout: 'noBorders'
    };
  }

  getBase64ImageFromURL(url: any) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx!.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
  prepareDelete(id: any) {
    this.offerIdToDelete = id;
  }
  // Method to confirm deletion of an offer
  confirmDelete() {
    this.offerService.deleteOffer(this.offerIdToDelete).subscribe({
      next: (res) => {
        // Find the dismiss button of the modal using its data-bs-dismiss attribute
        this.closebutton1.nativeElement.click();

        // Refresh the offer list after successful deletion
        this.redirectUserBasedOnRole();

        // Optionally, if you have a specific method to close the modal (e.g., in Angular/Bootstrap 5), you can call it here
        // this.hideModal(); // Uncomment and implement hideModal if needed
      },
      error: (error) => {
        console.error('Error deleting offer:', error);
        // Handle the error scenario (e.g., displaying an error message to the user)
      }
    });
  }
  redirectUserBasedOnRole() {
    // Obtient le rôle de l'utilisateur à partir du stockage local
    const userRole = localStorage.getItem('role'); // Assurez-vous que 'userRole' est la clé correcte

    if (userRole === 'admin') {
      // Redirection si l'utilisateur est un admin
      this.router.navigate(['/Login']); // Remplacez '/chemin-pour-admin' par votre chemin réel
    } else {
      // Redirection pour d'autres rôles d'utilisateurs
      this.router.navigate(['/myrequests']); // Remplacez '/autre-chemin' par votre chemin réel
    }
  }
}