import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr'; // Assuming you are using ngx-toastr
import { AuthService } from 'src/app/Shared/Services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isAuth = false;
  role = localStorage.getItem('role');
  loginForm!: FormGroup; // Renamed for clarity

  constructor(
    private fb: FormBuilder, // Single FormBuilder instance
    private authService: AuthService, // Renamed for clarity
    private router: Router,
    private jwtHelper: JwtHelperService,
    private toastr: ToastrService // Inject ToastrService
  ) { }

  ngOnInit(): void {
    this.isAuthenticated();
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]], // Added email validator
      password: ['', Validators.required],
    });
  }

  isAuthenticated() {
    const token = localStorage.getItem('token');
    this.isAuth = !!token && !this.jwtHelper.isTokenExpired(token);
    if (this.isAuth) {
      // Navigate based on role or perform additional checks
      this.role = localStorage.getItem('role');
    }
  }

  loginUser() {
    if (this.loginForm.valid) {
      const data = this.loginForm.value;

      this.authService.loginuser(data).subscribe(
        (res) => {
          this.toastr.success('Bienvenue ! Vous êtes maintenant connecté.');
          const decodedToken = this.jwtHelper.decodeToken(res.token);
          localStorage.setItem('role', res.result[0].role);
          localStorage.setItem('name', res.result[0].name);
          localStorage.setItem('id', res.result[0]._id);
          localStorage.setItem('token', res['token']);
          this.isAuthenticated(); // Re-check authentication status

          // Correctly retrieve and compare the role for navigation
          const role = localStorage.getItem('role'); // Retrieve the role from local storage

          // Use the retrieved role to determine the navigation destination
          const destination = role === 'user' ? '/add' : '/dashboard';
          this.router.navigate([destination]);
        },
        (err) => {
          this.toastr.error(err.error);
        }
      );
    } else {
      this.toastr.error('Veuillez remplir le formulaire correctement.');
    }
  }
}