<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">LISTE DES BONUS</h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-6">
                                    </div>
                                    <div class="col-lg-6 text-end">
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                            class="btn btn-dark">
                                            <i class="uil-plus me-1"></i> <span>Nouveau Bonus</span>
                                        </button>
                                    </div>
                                </div>
                                <table class="table table-centered mb-0" style="margin-top: 30px;">
                                    <thead>
                                        <tr>
                                            <th>Référence</th>
                                            <th>Image</th>
                                            <th>Bonus</th>
                                            <th>Valeur</th>
                                            <th>Description</th>
                                            <th>Statut</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let bonus of listbonus | paginate: { itemsPerPage: 5,currentPage: p }; let i = index">
                                            <td>#B{{bonus._id.substring(0,5)}}</td>
                                            <td>
                                                <img [src]="bonus.image" alt="table-user" class="img-fluid rounded"
                                                    width="200" />
                                            </td>
                                            <td>{{bonus.type}}</td>
                                            <td>{{bonus.value}}</td>
                                            <td>{{bonus.description}}</td>
                                            <td>
                                                <span class=" form-check form-switch">
                                                    <input class="form-check-input" type="checkbox"
                                                        [id]="'switch' + bonus._id"
                                                        [checked]="bonus.status === 'active'"
                                                        (click)=changeStatus(bonus) />
                                                </span>

                                            </td>
                                            <td class="table-action">
                                                <a type="button" data-bs-toggle="modal"
                                                    (click)="prepareDelete(bonus._id)"
                                                    data-bs-target="#danger-alert-modal" href="javascript: void(0);"
                                                    class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div
                                    style="display: flex; justify-content: center; align-items: center;margin-top: 20px;">
                                    <nav>
                                        <ul class="pagination pagination-rounded mb-0">
                                            <li class="page-item">
                                                <pagination-controls (pageChange)="p = $event"
                                                    class="pagination-controls"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div> <!-- end col -->
                </div>

            </div> <!-- container -->

        </div> <!-- content -->

        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->

<div id="danger-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content modal-filled bg-danger">
            <div class="modal-body p-4">
                <div class="text-center">
                    <i class="ri-close-circle-line h1"></i>
                    <h4 class="mt-2">Confirmer la suppression</h4>
                    <p class="mt-3">Êtes-vous sûr de vouloir supprimer cet élément ? Cette action est irréversible.</p>
                    <button type="button" class="btn btn-light my-2" style="margin-right: 10px;"
                        (click)="confirmDelete()">Continuer</button>
                    <button type="button" class="btn btn-light my-2" data-bs-dismiss="modal"
                        #closebutton>Annuler</button>
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter un nouveau bonus</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form (ngSubmit)="add()">
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="bonus" class="form-label">Bonus:</label>
                        <input type="text" class="form-control" id="bonus" [(ngModel)]="bonusName" name="bonusName">
                    </div>
                    <div class="mb-3">
                        <label for="valeur" class="form-label">Valeur:</label>
                        <input type="text" class="form-control" id="valeur" [(ngModel)]="bonusValue" name="bonusValue">
                    </div>
                    <div class="mb-3">
                        <label for="description" class="form-label">Description:</label>
                        <textarea class="form-control" id="description" [(ngModel)]="bonusDescription"
                            name="bonusDescription"></textarea>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Importer une image</label>
                        <input class="form-control" type="file" id="image" (change)="onFileSelected($event)"
                            name="image">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" #closebutton
                        data-bs-dismiss="modal">Annuler</button>
                    <button type="submit" class="btn btn-dark" data-bs-dismiss="modal">Ajouter</button>
                </div>
            </form>
        </div>
    </div>
</div>