import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  getNotificationsNumber(user: any) {
    return this.http.get<any>(
      `${AppConfig.notUrl}/number/${user}`
    );
  }

  getNotifications(user: any) {
    return this.http.get<any>(
      `${AppConfig.notUrl}/get/${user}`
    );
  }
}
