import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/Public/home/home.component';
import { LoginComponent } from './Components/Public/login/login.component';
import { DashboardComponent } from './Components/Private/User/dashboard/dashboard.component';
import { FooterComponent } from './Components/Private/Shared/footer/footer.component';
import { HeaderComponent } from './Components/Private/Shared/header/header.component';
import { SidebarComponent } from './Components/Private/Shared/sidebar/sidebar.component';
import { BonusComponent } from './Components/Private/User/bonus/bonus.component';
import { MyoffersComponent } from './Components/Private/User/myoffers/myoffers.component';
import { MyrequestComponent } from './Components/Private/User/myrequest/myrequest.component';
import { DetailsComponent } from './Components/Private/User/details/details.component';
import { UsersComponent } from './Components/Private/Admin/users/users.component';
import { BonusManagmentComponent } from './Components/Private/Admin/bonus-managment/bonus-managment.component';
import { ComponentsComponent } from './Components/Private/Admin/components/components.component';
import { AddComponent } from './Components/Private/User/add/add.component';
import { LaptopWizardComponent } from './Components/Private/User/Wizard/laptop-wizard/laptop-wizard.component';
import { DesktopWizardComponent } from './Components/Private/User/Wizard/desktop-wizard/desktop-wizard.component';
import { MonitorWizardComponent } from './Components/Private/User/Wizard/monitor-wizard/monitor-wizard.component';
import { SettingsComponent } from './Components/Private/User/settings/settings.component';
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './Components/Public/register/register.component';
import { ResetpasswordComponent } from './Components/Public/resetpassword/resetpassword.component';
import { UpdatepasswordComponent } from './Components/Public/updatepassword/updatepassword.component';
import { NotFoundComponent } from './Components/Public/not-found/not-found.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RequestManagmentComponent } from './Components/Private/Admin/request-managment/request-managment.component';
import { OffersManagmentComponent } from './Components/Private/Admin/offers-managment/offers-managment.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    DashboardComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    BonusComponent,
    MyoffersComponent,
    MyrequestComponent,
    DetailsComponent,
    UsersComponent,
    BonusManagmentComponent,
    ComponentsComponent,
    AddComponent,
    LaptopWizardComponent,
    DesktopWizardComponent,
    MonitorWizardComponent,
    SettingsComponent,
    RegisterComponent,
    ResetpasswordComponent,
    UpdatepasswordComponent,
    NotFoundComponent,
    RequestManagmentComponent,
    OffersManagmentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // Add ToastrModule to imports
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
  ],
  providers: [{ provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService],
  bootstrap: [AppComponent]
})
export class AppModule { }
