import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, forkJoin, map, mergeMap, of } from 'rxjs';
import { BonusService } from 'src/app/Shared/Services/bonus.service';
import { OfferService } from 'src/app/Shared/Services/offer.service';
import { UserService } from 'src/app/Shared/Services/user.service';
import { ValuationService } from 'src/app/Shared/Services/valuation.service';
import { AppConfig } from 'src/app/Shared/app.config';

@Component({
  selector: 'app-offers-managment',
  templateUrl: './offers-managment.component.html',
  styleUrls: ['./offers-managment.component.css']
})
export class OffersManagmentComponent implements OnInit {
  @ViewChild('closeButton') closeButton: ElementRef | any;

  Vals: any;
  offers: any;
  p: number = 1;
  selectedStatus: string = '';
  bonus: any;
  FormAccept: FormGroup;
  FormReject: FormGroup;
  searchTerm: string = ''; // Add a property to store the search term


  constructor(private userService: UserService,
    private valuationService: ValuationService,
    private bonusService: BonusService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
  ) {

    this.FormAccept = this.fb.group({
      bonus_name: [''],
      bonus_amount: [''],
      status: ['']
    }),
      this.FormReject = this.fb.group({
        comment: [''],
        status: ['',]
      })


  }
  ngOnInit(): void {
    this.getrespondedvaluations();
  }

  // Method to filter valuations based on search term
  filterValuationsBySearchTerm(): void {
    if (this.searchTerm.trim() === '') {
      // If the search term is empty, display all valuations
      this.getrespondedvaluations();
    } else {
      // Filter valuations based on search term
      this.Vals = this.Vals.filter((val: any) => {
        const searchTermLower = this.searchTerm.toLowerCase();
        const userNameMatches = val.user.name.toLowerCase().includes(searchTermLower);
        const dateMatches = val.date.toLowerCase().includes(searchTermLower);
        // Convert val.date to a JavaScript Date object
        const valDate = new Date(val.date);

        // Check if val.date is a valid date and matches the search term
        const isDateValid = !isNaN(valDate.getTime()) && (valDate.toDateString().toLowerCase().includes(searchTermLower));

        return userNameMatches || dateMatches || isDateValid;
      });
    }
  }


  getrespondedvaluations() {
    // Retrieve all users
    this.userService.getUsers().pipe(
      mergeMap((users: any[]) => {
        // Get responded valuations
        return this.valuationService.getrespondedvaluation().pipe(
          map((vals: any[]) => {
            // Filter vals based on user IDs
            const filteredVals = vals.filter(val => users.some(user => user._id === val.user_id));
            // Now map each valuation to include user info
            return filteredVals.map((val: any) => {
              const userInfo = users.find(user => user._id === val.user_id);
              return { ...val, user: userInfo };
            });
          })
        );
      })
    ).subscribe((resWithUsers: any[]) => {
      this.Vals = resWithUsers;
      // You can process this.Vals further or use it as needed.
    });
  }




  onChange(event: string) {


    if (event !== '') {
      this.selectedStatus = event;
      this.valuationService
        .getrespondedvaluationstat(event)
        .subscribe((result) => {
          this.Vals = result;
        });
    } else {
      this.valuationService.getrespondedvaluation().subscribe((result) => {
        this.Vals = result;

      });
    }
  }

  // Method to decline an offer
  decline() {
    this.FormReject.get('status')?.setValue('Rejected');

    this.valuationService.finalAdminResponse(localStorage.getItem('idToDecline'), this.FormReject.value)
      .subscribe({
        next: (res) => {
          this.getrespondedvaluations();
          this.closeButton.nativeElement.click();
          this.toastr.success('Offre rejetée avec succès');
        },
        error: (err) => {
          this.toastr.error('Une erreur est survenue');
        }
      });
  }

  // Method to accept an offer
  accept() {
    this.FormAccept.get('status')?.setValue('Accepted');

    this.valuationService.finalAdminResponse(localStorage.getItem('idToAccept'), this.FormAccept.value)
      .subscribe({
        next: (res) => {
          this.getrespondedvaluations();
          this.closeButton.nativeElement.click();
          this.toastr.success('Offre finalisée avec succès');
        },
        error: (err) => {
          this.toastr.error('Une erreur est survenue');
        }
      });
  }
  setIdToDecline(id: any): void {
    localStorage.setItem('idToDecline', id);
  }


  setIdToAccept(id: any): void {
    localStorage.setItem('idToAccept', id);
  }

  redirectToDetails(id: string) {
    // Save the ID in local storage
    localStorage.setItem('offerId', id);

    // Route to '/details'
    this.router.navigate(['/details']);
  }

  filterValuationsByAdminStatus(status: string): void {
    // Retrieve all users
    this.userService.getUsers().pipe(
      mergeMap((users: any[]) => {
        // Get responded valuations
        return this.valuationService.getrespondedvaluationstat(status).pipe(
          map((vals: any[]) => {
            // Filter vals based on user IDs
            const filteredVals = vals.filter(val => users.some(user => user._id === val.user_id));
            // Now map each valuation to include user info
            return filteredVals.map((val: any) => {
              const userInfo = users.find(user => user._id === val.user_id);
              return { ...val, user: userInfo };
            });
          })
        );
      })
    ).subscribe((resWithUsers: any[]) => {
      // Filter the result based on the status
      if (status === '') {
        // No filter applied, return all valuations
        this.Vals = resWithUsers;
      } else if (status === 'Accepted') {
        // Filter for valuations accepted by admin
        this.Vals = resWithUsers.filter((val: any) =>
          val.response_admin.status === 'Accepted' ||
          (val.response && val.response.status === 'Accepted' && val.response_admin.status === 'pending')
        );
      } else if (status === 'Rejected') {
        // Filter for valuations rejected by admin or by user
        this.Vals = resWithUsers.filter((val: any) =>
          val.response_admin.status === 'Rejected' ||
          (val.response && val.response.status === 'Rejected')
        );
      } else if (status === 'pending') {
        // Filter for valuations that are pending (not yet finalized by admin or user)
        this.Vals = resWithUsers.filter((val: { response: { status: string; }; response_admin: { status: string; }; }) =>
          val.response.status === 'pending' ||
          (val.response_admin.status !== 'pending')
        );
      } else if (status === 'augmentation') {
        // Filter for valuations where an increase has been requested
        this.Vals = resWithUsers.filter((val: { response: { status: string; }; response_admin: { status: string; }; }) =>
          val.response.status === 'augmentation'

        );
      }
    });
  }


}
