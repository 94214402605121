<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">LISTE DES OFFRES ENVOYÉES</h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-6">
                                        <select class="form-select form-select-sm mb-3" aria-label="Select status"
                                            [(ngModel)]="selectedStatus"
                                            (change)="filterValuationsByAdminStatus(selectedStatus)">
                                            <option value="">Tous</option> <!-- Added option for "All" -->
                                            <option value="Accepted">Offres acceptées</option>
                                            <option value="Rejected">Offres rejetées</option>
                                            <option value="pending">Offres en cours</option>
                                            <option value="augmentation">Demandes d'augmentation</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Chercher..."
                                                [(ngModel)]="searchTerm">
                                            <button class="btn btn-dark" type="button"
                                                (click)="filterValuationsBySearchTerm()">Chercher</button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th>Réference</th>
                                            <th>Utilisateur</th>
                                            <th>prix final</th>
                                            <th>Date de creation</th>
                                            <th>Livraison</th>
                                            <th>Numero de Téléphone</th>
                                            <th>Etat</th>
                                            <th>Statut</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let val of Vals |paginate: { itemsPerPage: 10,currentPage: p }; let i = index">
                                            <td>
                                                <a (click)="redirectToDetails(val.offre_id)" href="javascript:void(0)">
                                                    PC#{{val.offre_id.substring(18,24)}}
                                                </a>
                                            </td>
                                            <td>{{val.user.name}}</td>
                                            <td>{{val.given_price}}</td>
                                            <td>{{val.date}}</td>
                                            <td>{{val.response.address}}</td>
                                            <td>{{val.response.phone}}</td>

                                            <td>
                                                <ng-container
                                                    *ngIf="val.response_admin.status === 'Accepted' && val.response.status === 'Accepted'">
                                                    <i class="mdi mdi-circle text-success"></i> Acception final
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="val.response_admin.status === 'pending' && val.response.status === 'Accepted'">
                                                    <i class="mdi mdi-circle text-success"></i> Acception non final
                                                </ng-container>

                                                <ng-container *ngIf="val.response_admin.status == 'Rejected'">
                                                    <i class="mdi mdi-close-circle text-danger"></i> Rejeté
                                                </ng-container>
                                                <ng-container *ngIf="val.response.status == 'Rejected'">
                                                    <i class="mdi mdi-close-circle text-danger"></i> Rejeté
                                                </ng-container>


                                                <ng-container *ngIf="val.response.status =='augmentation'">
                                                    <i class="mdi mdi-alert-circle text-warning"></i> Demande
                                                    d'augmentation

                                                </ng-container>


                                                <ng-container *ngIf="val.response.status =='pending'" && val.>
                                                    <i class="mdi mdi-alert-circle text-info"></i> En cours
                                                </ng-container>
                                            </td>
                                            <td>
                                                <!-- Conditionally display the progress bar only when certain statuses are met and not when showing buttons -->
                                                <div *ngIf="!(val.response.status === 'Accepted' && val.response_admin.status === 'pending')"
                                                    class="progress progress-sm">
                                                    <div *ngIf="val.response_admin.status === 'Accepted' && val.response.status === 'Accepted'"
                                                        class="progress-bar bg-success" role="progressbar"
                                                        style="width: 100%" aria-valuenow="100" aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                    <div *ngIf="val.response.status === 'augmentation'"
                                                        class="progress-bar bg-warning" role="progressbar"
                                                        style="width: 50%"></div>
                                                    <div *ngIf="val.response_admin.status === 'Rejected'"
                                                        class="progress-bar bg-danger" role="progressbar"
                                                        style="width: 100%"></div>
                                                    <div *ngIf="val.response.status === 'Rejected'"
                                                        class="progress-bar bg-danger" role="progressbar"
                                                        style="width: 100%"></div>
                                                    <div *ngIf="val.response.status === 'pending'"
                                                        class="progress-bar bg-info" role="progressbar"
                                                        style="width: 25%"></div>
                                                </div>

                                                <!-- Buttons are displayed without the progress bar when the specific condition is met -->
                                                <ng-container
                                                    *ngIf="val.response.status === 'Accepted' && val.response_admin.status === 'pending'">
                                                    <button (click)="setIdToAccept(val._id); accept()" type="button"
                                                        class="btn btn-outline-success me-1">Accepter</button>
                                                    <button (click)="setIdToDecline(val._id)" type="button"
                                                        data-bs-toggle="modal" data-bs-target="#declineModal"
                                                        class="btn btn-outline-danger me-1">Refuser</button>
                                                </ng-container>
                                            </td>
                                    </tbody>
                                </table>
                                <div
                                    style="display: flex; justify-content: center; align-items: center;margin-top: 20px;">
                                    <nav>
                                        <ul class="pagination pagination-rounded mb-0">
                                            <li class="page-item">
                                                <pagination-controls (pageChange)="p = $event"
                                                    class="pagination-controls"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div> <!-- end col -->
                </div>

            </div> <!-- container -->

        </div> <!-- content -->
        <div class="modal fade" id="declineModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Rejeter offre</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- Begin form with Angular formGroup directive -->
                        <form [formGroup]="FormReject">
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">Ajouter un commentaire justificatif de
                                    votre Rejet</label>
                                <!-- Bind textarea to the form control using formControlName -->
                                <textarea class="form-control" id="message-text" formControlName="comment"></textarea>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            #closeButton>Close</button>
                        <!-- Bind the confirm button to call the decline method on click -->
                        <button type="button" class="btn btn-primary" (click)="decline()">Confirmer</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModal1Label"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Ajouter bonus</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- Begin form with Angular formGroup directive -->
                        <form [formGroup]="FormAccept">
                            <div class="mb-3">
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="bonus_name">
                                    <option value="" disabled selected>Choisir le type de bonus</option>
                                    <option *ngFor="let type of bonus" [value]="type.type">{{type.type}}</option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            #closeButton>Close</button>
                        <!-- Bind the confirm button to call a method on click -->
                        <button type="button" class="btn btn-primary" (click)="accept()">Confirmer</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->