<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title"> Ajouter une demande </h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->


                <!-- product-wizard.component.html -->

                <div class="row">
                    <div class="col-sm-4">
                        <div class="card d-block">
                            <img class="card-img-top" src="assets/images/Laptop.jpg" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">Vendre votre laptop</h5>
                                <p class="card-text">Facilitez la vente de votre laptop. Estimation rapide et vente
                                    sécurisée.</p>
                                <a routerLink="/laptop-wizard" class="btn btn-primary">Commencer</a>
                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div>
                    <div class="col-sm-4">
                        <div class="card d-block">
                            <img class="card-img-top" src="assets/images/Desktop.jpg" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">Vendre votre ordinateur de bureau</h5>
                                <p class="card-text">Maximisez le retour avec un processus simple et un paiement rapide.
                                </p>
                                <a routerLink="/desktop-wizard" class="btn btn-primary">Commencer</a>
                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div>
                    <div class="col-sm-4">
                        <div class="card d-block">
                            <img class="card-img-top" src="assets/images/Monitor.jpg" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">Vendre votre écran</h5>
                                <p class="card-text">Transformez votre écran en cash. Vente facile et service fiable.
                                </p>
                                <a routerLink="/monitor-wizard" class="btn btn-primary">Commencer</a>
                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div>
                </div>



            </div> <!-- container -->

        </div> <!-- content -->

        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->