<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">LISTE DES OFFRES REÇUES </h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-6">
                                        <select class="form-select" id="example-select">
                                            <option>Tous les équipements</option>
                                            <option>Écran</option>
                                            <option>Laptop</option>
                                            <option>Ordinateur de bureau</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Chercher..."
                                                [(ngModel)]="searchQuery">
                                            <button class="btn btn-dark" type="button"
                                                (click)="search()">Chercher</button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th>Réference</th>
                                            <th>Équipement</th>
                                            <th>Marque</th>
                                            <th>Modele</th>
                                            <th>Date de creation</th>
                                            <th>Prix suggéré</th>
                                            <th>Prix proposé</th>
                                            <th>Statut</th>
                                            <th>Etat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Afficher un message si le tableau est vide -->
                                        <tr *ngIf="offers.length === 0">
                                            <td colspan="8" class="text-center">Aucune donnée disponible pour le moment.
                                            </td>
                                        </tr>
                                        <!-- Fin de l'affichage du message -->
                                        <tr
                                            *ngFor="let offer of offers | paginate: { itemsPerPage: 5,currentPage: p }; let i = index">
                                            <td>
                                                <a (click)="redirectToDetails(offer._id)" href="javascript:void(0)">
                                                    PC#{{offer._id.substring(18,24)}}
                                                </a>
                                            </td>
                                            <td [ngSwitch]="offer.type">
                                                <span *ngSwitchCase="'monitor'" class="badge bg-warning">Écran</span>
                                                <span *ngSwitchCase="'desktop'" class="badge bg-info">Ordinateur de
                                                    bureau</span>
                                                <span *ngSwitchDefault class="badge bg-primary">Laptop</span>
                                            </td>
                                            <td>{{offer.brand}}</td>
                                            <td>{{offer.model}}</td>
                                            <td>{{offer.date}}</td>
                                            <td>{{offer.price}}</td>
                                            <td>{{offer.valuations?.[0]?.given_price}}</td>
                                            <td>
                                                <div class="progress progress-sm">
                                                    <div *ngIf="offer.valuationStatus === 'accepted_admin'"
                                                        class="progress-bar bg-success" role="progressbar"
                                                        style="width: 100%"></div>
                                                    <div *ngIf="offer.valuationStatus === 'accepted_provisoire'"
                                                        class="progress-bar bg-warning" role="progressbar"
                                                        style="width: 50%"></div>
                                                    <div *ngIf="offer.valuationStatus === 'rejected_admin'"
                                                        class="progress-bar bg-danger" role="progressbar"
                                                        style="width: 100%"></div>
                                                    <div *ngIf="offer.valuationStatus === 'rejected_pending'"
                                                        class="progress-bar bg-danger" role="progressbar"
                                                        style="width: 50%"></div>
                                                    <div *ngIf="offer.valuationStatus === 'en_cour'"
                                                        class="progress-bar bg-info" role="progressbar"
                                                        style="width: 25%"></div>
                                                    <div *ngIf="offer.valuationStatus === 'not_evaluated'"
                                                        class="progress-bar bg-secondary" role="progressbar"
                                                        style="width: 25%"></div>
                                                </div>
                                            </td>


                                            <td>
                                                <ng-container *ngIf="offer.valuationStatus === 'accepted_admin'">
                                                    <i class="mdi mdi-check-circle text-success"></i> Accepté
                                                    définitivement
                                                </ng-container>
                                                <ng-container *ngIf="offer.valuationStatus === 'accepted_provisoire'">
                                                    <i class="mdi mdi-alert-circle text-warning"></i> Acceptation
                                                    provisoire
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="offer.valuationStatus === 'rejected_admin' || offer.valuationStatus === 'rejected_pending'">
                                                    <i class="mdi mdi-close-circle text-danger"></i> Rejeté
                                                </ng-container>
                                                <ng-container *ngIf="offer.valuationStatus === 'en_cour'">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <!-- Accepter: Exécutez SendThisVal et naviguez si nécessaire -->
                                                        <button type="button"
                                                            class="btn btn-outline-success btn-sm me-1"
                                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                            (click)="SendThisVal(offer.valuations)">Accepter</button>

                                                        <!-- Augmentation: Naviguez ou ouvrez une modal pour l'augmentation -->
                                                        <button #closebutton type="button"
                                                            class="btn btn-outline-info btn-sm me-1"
                                                            (click)="augmentation(offer.valuations)">Augmentation</button>


                                                        <!-- Refuser: Exécutez SendThisVal et ouvrez une modal si nécessaire -->
                                                        <button type="button" data-bs-toggle="modal"
                                                            data-bs-target="#danger-alert-modal"
                                                            class="btn btn-outline-danger btn-sm"
                                                            (click)="SendThisVal(offer.valuations)">Refuser</button>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="offer.valuationStatus === 'not_evaluated'">
                                                    <i class="mdi mdi-help-circle text-secondary"></i> Non évalué
                                                </ng-container>
                                            </td>

                                        </tr>



                                    </tbody>
                                </table>
                                <div
                                    style="display: flex; justify-content: center; align-items: center;margin-top: 20px;">
                                    <nav>
                                        <ul class="pagination pagination-rounded mb-0">
                                            <li class="page-item">
                                                <pagination-controls (pageChange)="p = $event"
                                                    class="pagination-controls"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div> <!-- end col -->
                </div>

            </div> <!-- container -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">DÉTAILS DE LA COLLECTE</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="formulaire">
                                <div class="mb-3">
                                    <select class="form-select" aria-label="Default select example"
                                        formControlName="livraison" interface="action-sheet"
                                        (ionChange)="LivraisonTypeChanged()">
                                        <option selected>Choisir le type de livraison</option>
                                        <option value="Enlèvement à domicile">Enlèvement à domicile</option>
                                        <option value="Dépôt au magasin">Dépôt au magasin</option>

                                    </select>
                                </div>
                                <div class="mb-3" *ngIf="isEnlevementADomicile()">
                                    <label class="col-form-label">Adresse de livraison:</label>
                                    <input type="text" class="form-control" formControlName="adresse">
                                </div>
                                <div class="mb-3" *ngIf="isEnlevementADomicile()">
                                    <label class="col-form-label">Numéro Téléphone:</label>
                                    <input type="text" class="form-control" formControlName="phone">
                                </div>
                                <div class="mb-3" *ngIf="isDepotAuMagasin()">
                                    <select class="form-select" aria-label="Selectionner boutique plus proche"
                                        (ionChange)="selectBoutique($event)">
                                        <option selected *ngFor="let b of boutique" [value]="b">{{b}}</option>


                                    </select>
                                </div>
                                <div class="mb-3" *ngIf="isDepotAuMagasin()">
                                    <label class="col-form-label">Numéro Téléphone:</label>
                                    <input type="text" class="form-control" formControlName="phone">
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                #closebutton2>Close</button>
                            <button type="button" class="btn btn-primary" (click)="SaveDilevery()">Confirmer</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="danger-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content modal-filled bg-danger">
                        <div class="modal-body p-4">
                            <div class="text-center">
                                <i class="ri-close-circle-line h1"></i>
                                <h4 class="mt-2">Vous êtes sûr?</h4>
                                <p class="mt-3">vous êtes sûr de vouloir refuser cet offre ? Ce processus ne peut pas
                                    être annulé.</p>
                                <button type="button" class="btn btn-light my-2" style="margin-right: 10px;"
                                    (click)="RejectOffer()">Continuer</button>
                                <button #closeModalButton type="button" class="btn btn-secondary"
                                    data-bs-dismiss="modal">Fermer</button>

                            </div>
                        </div>
                    </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
            </div><!-- /.modal -->
        </div> <!-- content -->

        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->