import { Component, OnInit, ViewChild } from '@angular/core';
import { BonusService } from 'src/app/Shared/Services/bonus.service';
import { AppConfig } from 'src/app/Shared/app.config';

@Component({
  selector: 'app-bonus-managment',
  templateUrl: './bonus-managment.component.html',
  styleUrls: ['./bonus-managment.component.css']
})
export class BonusManagmentComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  bonusName: string = '';
  bonusValue: string = '';
  bonusDescription: string = '';
  selectedImage: File | null = null;
  listbonus: any;
  bonusId: any;
  p: number = 1;

  constructor(private bonusService: BonusService) { }

  ngOnInit(): void {
    this.getAllAdmin()
  }

  getAllAdmin() {
    this.bonusService.getallAdmin().subscribe(res => {
      this.listbonus = res;
      for (let i = 0; i < res.length; i++) {
        this.listbonus[i].image = AppConfig.imgBonussUrl + '/' + res[i].image;
      }
    })
  }


  changeStatus(item: any) {
    const { _id, status } = item;
    const newStatus = status === 'inactive' ? 'active' : 'inactive';

    this.bonusService.changeStatus(_id, { id: _id, status: newStatus }).subscribe(
      (result) => {
        this.getAllAdmin();
      },
      (errors) => { }
    );
  }

  add() {
    const formData = new FormData();
    formData.append('type', this.bonusName); // Assuming 'type' corresponds to bonusName
    formData.append('value', this.bonusValue); // Assuming 'value' corresponds to bonusValue
    formData.append('description', this.bonusDescription); // Assuming 'description' corresponds to bonusDescription
    if (this.selectedImage) {
      formData.append('bonus_photo', this.selectedImage, this.selectedImage.name); // Assuming 'bonus_photo' corresponds to image
    }

    this.bonusService.add(formData).subscribe(
      (result) => {
        // Reset form fields after successful addition
        this.resetForm();
        // Refresh the list of bonuses
        this.getAllAdmin();
        // Close the modal
        this.closebutton.nativeElement.click();
      },
      (error) => {
        console.error('Error adding bonus:', error);
      }
    );
  }



  onFileSelected(event: any) {
    this.selectedImage = event.target.files[0];
  }

  resetForm() {
    this.bonusName = '';
    this.bonusValue = '';
    this.bonusDescription = '';
    this.selectedImage = null;
  }


  prepareDelete(id: any) {
    this.bonusId = id;
  }
  // Method to confirm deletion of an offer
  confirmDelete() {
    this.bonusService.delete(this.bonusId).subscribe({
      next: (res) => {
        // Find the dismiss button of the modal using its data-bs-dismiss attribute
        this.closebutton.nativeElement.click();

        // Refresh the offer list after successful deletion
        this.getAllAdmin();

        // Optionally, if you have a specific method to close the modal (e.g., in Angular/Bootstrap 5), you can call it here
        // this.hideModal(); // Uncomment and implement hideModal if needed
      },
      error: (error) => {
        console.error('Error deleting offer:', error);
        // Handle the error scenario (e.g., displaying an error message to the user)
      }
    });
  }
}

