import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/Shared/Services/auth.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {


  isAuth = false;
  role = localStorage.getItem('role');
  Form!: FormGroup;
  show: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toast: ToastrService
  ) {


  }

  ngOnInit(): void {
    this.Form = this.fb.group({
      email: ['', [Validators.required, Validators.email]], // Added email validator
    });
  }

  sendemail() {
    // Check if the form is valid
    if (this.Form.valid) {
      let data = this.Form.value;
      this.authService.sendlink(data).subscribe(res => {
        this.show = true;
        this.toast.success('Link sent successfully.'); // Display success message
        this.router.navigate(['/']); // Navigate to the home page
      }, err => {
        this.toast.error(err.error); // Display error message from the server response
      });
    } else {
      // If the form is not valid (e.g., the email field is empty), display an error message
      this.toast.error('Please fill in the email field.'); // Display error message for empty form
      this.Form.markAllAsTouched(); // Optionally mark all fields as touched to trigger validation messages
    }
  }



}