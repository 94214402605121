import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isAuth!: boolean;

  constructor() { }

  ngOnInit(): void {
    // JavaScript anonymous function
    (() => {
      if (window.localStorage) {
        // If there is no item as 'reload' in localstorage then create one &
        // reload the page
        if (!localStorage.getItem('reload')) {
          localStorage['reload'] = true;
          window.location.reload();
        } else {
          // If there exists a 'reload' item then clear the 'reload' item in local storage
          localStorage.removeItem('reload');
        }
      }
    })(); // Calling anonymous function here only
    this.checkScroll(); // Check scroll position on load in case it's already scrolled
    this.isAuthenticated();
  }

  isAuthenticated() {
    let token = localStorage.getItem('token');

    if (!!token) {
      this.isAuth = true;
    } else {
      this.isAuth = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const navbar = document.querySelector('.header_Header'); // Adjust if your navbar has a different class
    if (window.pageYOffset > 50) {
      navbar?.classList.add('header_White');
    } else {
      navbar?.classList.remove('header_White');
    }
  }
}