<div class="leftside-menu">

    <!-- Brand Logo Light -->
    <a href="javascript:void(0);" class="logo logo-light" style="margin-top: 30px;margin-bottom: 30px;">
        <span class="logo-lg">
            <img src="assets/static/Logo-Swapify-3.png" alt="logo">
        </span>
        <span class="logo-sm">
            <img src="assets/static/Logo-Swapify-3.png" alt="small logo">
        </span>
    </a>

    <!-- Brand Logo Dark -->
    <a href="javascript:void(0);" class="logo logo-dark" style="margin-top: 30px;margin-bottom: 30px;">
        <span class="logo-lg">
            <img src="assets/static/Logo-Swapify-3.png" alt="dark logo">
        </span>
        <span class="logo-sm">
            <img src="assets/static/Logo-Swapify-3.png" alt="small logo">
        </span>
    </a>

    <!-- Sidebar Hover Menu Toggle Button -->
    <div class="button-sm-hover" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
        <i class="ri-checkbox-blank-circle-line align-middle"></i>
    </div>

    <!-- Full Sidebar Menu Close Button -->
    <div class="button-close-fullsidebar">
        <i class="ri-close-fill align-middle"></i>
    </div>

    <!-- Sidebar -left -->
    <div class="h-100" id="leftside-menu-container" data-simplebar>
        <!-- Leftbar User -->
        <div class="leftbar-user">
            <a>
                <img src="assets/icons/1f475224-bf58-4e79-972d-1457995facf4_ICON_disponibiliteeaa9.gif" alt="user-image"
                    height="42" class="rounded-circle shadow-sm">
                <span class="leftbar-user-name mt-2">Yassine KARRECH</span>
            </a>
        </div>

        <!--- Sidemenu -->
        <ul class="side-nav">

            <li class="side-nav-item">
                <a data-bs-toggle="collapse" aria-expanded="false" aria-controls="sidebarDashboards"
                    routerLink="/dashboard" class="side-nav-link">
                    <i class="uil-dashboard"></i>
                    <span> Tableau de bord </span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'Admin'">
                <a routerLink="/users" class="side-nav-link">
                    <i class="uil-users-alt"></i>
                    <span> Liste des utilisateurs</span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'Admin'">
                <a routerLink="/requests" class="side-nav-link">
                    <i class="uil-folder"></i>
                    <span>Les demandes en attente</span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'Admin'">
                <a routerLink="/offers" class="side-nav-link">
                    <i class="uil-folder"></i>
                    <span> Les offres envoyées</span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'user'">
                <a routerLink="/add" class="side-nav-link">
                    <i class=" uil-file-plus-alt"></i>
                    <span> Ajouter une demande </span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'user'">
                <a routerLink="/myrequests" class="side-nav-link">
                    <i class="uil-folder-upload"></i>
                    <span> Mes demandes en attente </span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'user'">
                <a routerLink="/myoffers" class="side-nav-link">
                    <i class="uil-folder-download"></i>
                    <span> Mes offres reçues </span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'user'">
                <a routerLink="/bonus" class="side-nav-link">
                    <i class="uil-gift"></i>
                    <span> Les bonus swapify </span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'Admin'">
                <a routerLink="/managebonus" class="side-nav-link">
                    <i class="uil-store"></i>
                    <span> Gestion de bonus</span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="role == 'Admin'">
                <a routerLink="/components" class="side-nav-link">
                    <i class="uil-puzzle-piece"></i>
                    <span> Les composants swapify</span>
                </a>
            </li>

            <li class="side-nav-item">
                <a routerLink="/" class="side-nav-link">
                    <i class="uil-home-alt"></i>
                    <span> Revenir à l'acceuil </span>
                </a>
            </li>

        </ul>
        <!--- End Sidemenu -->

        <div class="clearfix"></div>
    </div>
</div>