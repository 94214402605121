import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { User } from '../Models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private myuser: User = new User();

  getUser() {
    return this.myuser;
  }

  constructor(private http: HttpClient, private router: Router) { }

  adduser(user: any) {
    return this.http.post<any>(`${AppConfig.userUrl}/signup`, user);
  }

  loginuser(user: any) {
    return this.http.post<any>(`${AppConfig.authUrl}/signin`, user);
  }

  sendlink(user: any) {
    return this.http.post<any>(`${AppConfig.authUrl}/resetPassword`, user);
  }

  logout() {
    localStorage.clear();
    localStorage.removeItem('token');
    this.router.navigate(['/Login']);
  }

  changePassword(user: any) {
    return this.http.post<any>(`${AppConfig.userUrl}/updatePassword`, user);
  }

  resetPassword(user: any, token: any) {
    return this.http.post<any>(`${AppConfig.authUrl}/reset/${token}`, user);
  }

  checkExpired(user: any, token: any) {
    return this.http.post<any>(`${AppConfig.authUrl}/check/${token}`, user);
  }

}