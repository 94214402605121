import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BonusService {

  constructor(private http: HttpClient) { }

  getall() {

    let data = this.http.get<any>(`${AppConfig.bonusUrl}/getAllBonus/`);
    console.log(data);

    return data;
  }
  getallAdmin() {

    let data = this.http.get<any>(`${AppConfig.bonusUrl}/allBonusAdmin/`);
    console.log(data);

    return data;
  }
  add(any: any) {
    return this.http.post<any>(`${AppConfig.bonusUrl}/addBonus`, any);
  }
  getById(id: any) {
    return this.http.get<any>(`${AppConfig.bonusUrl}/getBonusById/${id}`);
  }
  delete(id: any) {
    return this.http.delete<any>(`${AppConfig.bonusUrl}/deleteBonus/${id}`);
  }

  changeStatus(id: any, status: any) {
    return this.http.patch<any>(`${AppConfig.bonusUrl}/changeStatus/${id}`, status);
  }
  getBonusByStatus(status: any) {
    return this.http.get<any[]>(`${AppConfig.bonusUrl}/getBonusByStatus/`, status);
  }
}
