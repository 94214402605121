<div id="root">
    <main gridtemplatecolumns="50% 50%" data-testid="layout" class="hYsyuB">
        <div class="cjpdQ">
            <div class="bZzVws  fYucMZ">
                <div class="bZzVws  bTflzG">
                    <div class="bKYSlz">
                        <picture>
                            <source sizes="@media (max-width: 1279px) 100vw, 50vw" type="image/jpeg"><img
                                src="assets/images/background_gradient_xl.jpg" alt="background gradient">
                        </picture>
                    </div>
                    <div class="bZzVws  kDKymR">
                        <div class="bZzVws">
                            <div class="lbhavv">
                                <div class="dnOXYm"><a routerLink="/">
                                        <picture>
                                            <source type="image/webp"><img src="assets/static/Logo-Swapify-3.png"
                                                alt="Swapify logo">
                                        </picture>
                                    </a>
                                </div>
                            </div>
                            <div class="brctdI">
                                <div class="login-container">
                                    <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
                                        <div class="galqqa">
                                            <h2 class="dAGLka">Se connecter à Swapify</h2>
                                            <div class="kEiiFO">
                                                <div class="eTtDmO">
                                                    <div class="eJPFMs  htXUem" size="56"><label for="email"
                                                            class="dffLbX  ddEEZM"> Adresse e-mail</label>
                                                        <input id="email" formControlName="email" type="email"
                                                            autocomplete="off" spellcheck="false" class="jnzpqg"
                                                            value="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="gwjbDQ">
                                                <div class="bYbEfh">
                                                    <div class="flwtlQ">
                                                        <div class="eJPFMs  htXUem" size="56">
                                                            <label for="password" class="dffLbX  ddEEZM">
                                                                Mot de passe</label>
                                                            <input id="password" formControlName="password"
                                                                type="password" spellcheck="false" autocomplete="off"
                                                                class="jnzpqg" value="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a class="uLtjf" routerLink="/Reset">Mot de
                                                passe oublié ?</a>
                                            <a class="uLtjf" routerLink="/Register">Inscrivez-vous maintenant</a>

                                            <div class="jBgCDI"><button type="submit" aria-label="submit"
                                                    class="jmeDk gWKcWZ">Se connecter</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div data-testid="layout-right-column" class="kXfLYH">
            <div class="bKYSlz cYvBZE">
                <picture>
                    <source sizes="@media (max-width: 1279px) 100vw, 50vw" type="image/jpeg"><img
                        src="assets/images/DALL.webp" alt="laptop">
                </picture>
            </div>
        </div>
    </main>
</div>