<main>
    <div>
        <h1 class="Swile">404</h1>
        <p class="Swile TextGradient paragraph">Oups, il y a un petit problème sur le site. On est sur le coup et on
            fait le
            maximum pour le régler.</p>
        <p class="Graphik paragraph2">
            En attendant, vous pouvez aller voir les pages qui fonctionnent parfaitement :
        </p>
        <a class="Btn Btn--Big Btn--White" routerLink="/">Retour à l'accueil</a>
    </div>
</main>