import { OfferService } from 'src/app/Shared/Services/offer.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-laptop-wizard',
  templateUrl: './laptop-wizard.component.html',
  styleUrls: ['./laptop-wizard.component.css']
})
export class LaptopWizardComponent implements OnInit {

  // Step properties
  selectedBrand: string = '';
  selectedModel: string = '';
  selectedCpu: string = '';
  selectedCpuGen: string = '';
  selectedGPU: string = '';
  screenSize: string = '';
  selectedRam: string = '';
  selectedStorageType: string = '';
  selectedStorageSize: string = '';
  isFunctional: string = '';
  aestheticCondition: string = '';
  screenCondition: string = '';
  batteryCondition: string = '';
  chargerCondition: string = '';
  chargerType: string = '';
  keyboardLayout: string = '';
  keyboardBacklight: string = '';
  uploadedPhotos: File[] = []; // Handling multiple photos upload
  desiredPrice: string = '';
  additionalInfo: string = '';
  email: string = '';
  phone: string = '';
  type: string = '';

  // Booleans to control visibility of custom inputs
  showCustomCpu: boolean = false;
  showCustomCpuGen: boolean = false;
  showCustomGPU: boolean = false;
  showCustomScreenSize: boolean = false;

  // Property to store the offer data
  myForm!: FormGroup; // Define myForm property here

  // File upload handling
  myFiles: File[] = [];
  photo: File[] = []; // Assuming multiple photos can be uploaded

  imagePreviews: string[] = []; // Store image previews


  constructor(private offerService: OfferService, private toastr: ToastrService, private router: Router, private fb: FormBuilder) {

    // Initialize FormGroup
    this.myForm = this.fb.group({
      brand: ['', Validators.required], // Add Validators.required to make it required
      model: [''],
      cpu: [''],
      cpu_gen: [''],
      gpu: [''],
      monitor_size: [''],
      ram: [''],
      storage_type: [''],
      storage_size: [''],
      functional: [''],
      pc_status: [''],
      monitor_status: [''],
      battery_status: [''],
      charger_status: [''],
      charger_type: [''],
      keyboard_type: [''],
      keyboard_retro: [''],
      price: [''],
      email: [''],
      phone: [''],
      type: [''],
      photo: this.fb.array([]) // Initialize 'photo' as a FormArray
      // Add more form controls as needed
    });
  }

  ngOnInit(): void {
    // Refresh the page once to clear local storage
    this.refreshPage();
  }

  refreshPage(): void {
    if (window.localStorage) {
      if (!localStorage.getItem('reload')) {
        localStorage['reload'] = true;
        window.location.reload();
      } else {
        localStorage.removeItem('reload');
      }
    }
  }

  // Update functions for step properties
  updateSelectedBrand(brand: string) {
    this.selectedBrand = brand;
  }

  updateCPU(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue === 'Autre option') {
      this.showCustomCpu = true;
    } else {
      this.showCustomCpu = false;
      this.selectedCpu = selectedValue;
    }
  }

  updateCPUGeneration(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue === 'Autre option') {
      this.showCustomCpuGen = true;
    } else {
      this.showCustomCpuGen = false;
      this.selectedCpuGen = selectedValue;
    }
  }

  updateGPU(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue === 'Autre option') {
      this.showCustomGPU = true;
    } else {
      this.showCustomGPU = false;
      this.selectedGPU = selectedValue;
    }
  }

  updateScreenSize(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue === 'Autre option') {
      this.showCustomScreenSize = true;
    } else {
      this.showCustomScreenSize = false;
      this.screenSize = selectedValue;
    }
  }

  updateSelectedRAM(event: any) {
    this.selectedRam = event.target.value;
  }

  updateSelectedStorageType(event: any) {
    this.selectedStorageType = event.target.value;
  }

  updateSelectedStorageSize(event: any) {
    this.selectedStorageSize = event.target.value;
  }

  updateSelectedFunctionality(condition: string) {
    this.isFunctional = condition;
  }

  updateSelectedAestheticCondition(condition: string) {
    this.aestheticCondition = condition;
  }

  async preprocessImages(): Promise<void> {
    // Loop through each selected image and preprocess the previews
    for (const file of this.photo) {
      // Get the preview for the current image
      const preview = await this.getFilePreview(file);

      // Push the processed preview to the array
      this.imagePreviews.push(preview);
    }
  }

  updateScreenCondition(condition: string) {
    this.screenCondition = condition;
  }

  updateBatteryCondition(condition: string) {
    this.batteryCondition = condition;
  }

  updateChargerCondition(condition: string) {
    this.chargerCondition = condition;
  }

  updateChargerType(type: string) {
    this.chargerType = type;
  }

  updateKeyboardLayout(layout: string) {
    this.keyboardLayout = layout;
  }

  updateKeyboardBacklight(hasBacklight: string) {
    this.keyboardBacklight = hasBacklight;
  }

  async getFilePreview(file: File): Promise<string> {
    // Create a FileReader instance
    const reader = new FileReader();

    // Create a promise to handle the file load
    return new Promise<string>((resolve, reject) => {
      // Event listener to handle the file load
      reader.onload = () => {
        // Resolve the data URL
        resolve(reader.result as string);
      };

      // Event listener to handle errors
      reader.onerror = (error) => {
        // Reject with the error message
        reject(error);
      };

      // Read the file as a data URL, triggering the `onload` event
      reader.readAsDataURL(file);
    });
  }

  // Update onFileSelected method
  async onFileSelected(event: any): Promise<void> {
    const files: FileList = event.target.files;

    // Clear existing files
    this.myFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.myFiles.push(file);
    }

    // Generate previews
    this.generateImagePreviews();
  }

  // Generate image previews
  async generateImagePreviews(): Promise<void> {
    this.imagePreviews = [];

    for (let i = 0; i < this.myFiles.length; i++) {
      const file = this.myFiles[i];
      const dataUrl = await this.getFilePreview(file);
      this.imagePreviews.push(dataUrl);
    }
  }

  // Add removeImage method to remove selected image
  removeImage(index: number): void {
    this.myFiles.splice(index, 1); // Remove file from myFiles array
    this.imagePreviews.splice(index, 1); // Remove corresponding preview
  }
  // Array properties
  brandsList = [
    'ACER', 'AOC', 'APPLE', 'ASUS', 'BENQ', 'DELL', 'DYSON', 'FUJITSU', 'GIGABYTE',
    'GOOGLE', 'HP', 'HUAWEI', 'HONOR', 'LENOVO', 'LG', 'MICROSOFT', 'MSI', 'PANASONIC',
    'PHILIPS', 'RAZER', 'SAMSUNG', 'SONY', 'TOSHIBA', 'VIEWSONIC', 'XIAOMI', 'ALIENWARE',
    'RAZER', 'THOMSON', 'SAGER', 'MICRO-STAR INTERNATIONAL (MSI)', 'CLEVO', 'ELUKTRONICS',
    'ROG (Republic of Gamers - ASUS sub-brand)', 'PREDATOR (ACER sub-brand)', 'OMEN (HP sub-brand)',
    'ALIENWARE (DELL sub-brand)', 'LEGION (LENOVO sub-brand)', 'AORUS (GIGABYTE sub-brand)',
    'ZOTAC', 'SHARP', 'NEC', 'EIZO', 'IIYAMA', 'AUTRE'
  ];

  // Array of CPU models
  cpuList = [
    // Intel CPUs
    'Intel Core i3', 'Intel Core i5', 'Intel Core i7', 'Intel Core i9',
    'Intel Xeon', 'Intel Pentium', 'Intel Celeron', 'Intel Atom',
    // AMD CPUs
    'AMD Ryzen 3', 'AMD Ryzen 5', 'AMD Ryzen 7', 'AMD Ryzen 9',
    'AMD Athlon', 'AMD EPYC', // Adding EPYC for completeness, though more server-focused
    // Apple CPUs
    'Apple M1', 'Apple M1 Pro', 'Apple M1 Max', 'Apple M2', // Assuming future models
    // ARM CPUs (for laptops that use ARM-based processors)
    'Qualcomm Snapdragon', 'Samsung Exynos', 'MediaTek',
  ];

  // Array of CPU generations
  cpuGenerationList = [
    // Intel CPU generations (hypothetical update)
    '1st Generation', '2nd Generation', '3rd Generation', '4th Generation', '5th Generation',
    '6th Generation', '7th Generation', '8th Generation', '9th Generation', '10th Generation',
    '11th Generation', '12th Generation', '13th Generation', // Assuming up to 13th is current
    // AMD CPU generations (hypothetical update)
    'Athlon 64', 'Phenom', 'Ryzen 1000 Series', 'Ryzen 2000 Series', 'Ryzen 3000 Series',
    'Ryzen 4000 Series', 'Ryzen 5000 Series', 'Ryzen 6000 Series', 'Ryzen 7000 Series', // Extending to 7000 series
    // Apple CPU generations (hypothetical update)
    'Apple M1 Series', 'Apple M2 Series', // Adding M2 as a logical next step
    // ARM CPU generations
    'ARM Cortex-A7', 'ARM Cortex-A53', 'ARM Cortex-A72', 'ARM Cortex-A76', 'ARM Cortex-A78',
    // Adding newer ARM architectures hypothetically
    'ARM Cortex-A510', 'ARM Cortex-A710', 'ARM Cortex-X2' // These are made-up for illustration
  ];

  // GPU list
  gpuList = [
    // NVIDIA GPUs
    'NVIDIA GeForce GTX 900 Series', 'NVIDIA GeForce GTX 10 Series', 'NVIDIA GeForce GTX 16 Series',
    'NVIDIA GeForce GTX 20 Series', 'NVIDIA GeForce GTX 30 Series', 'NVIDIA GeForce RTX 20 Series',
    'NVIDIA GeForce RTX 30 Series', 'NVIDIA Quadro Series', 'NVIDIA Titan Series',
    // AMD GPUs
    'AMD Radeon HD Series', 'AMD Radeon R7 Series', 'AMD Radeon R9 Series', 'AMD Radeon RX 400 Series',
    'AMD Radeon RX 500 Series', 'AMD Radeon RX 5000 Series', 'AMD Radeon RX 6000 Series',
    'AMD Radeon Pro Series',
    // Intel integrated graphics
    'Intel HD Graphics', 'Intel Iris Graphics', 'Intel UHD Graphics', 'Intel Xe Graphics'
    // Add more GPU series as needed
  ];

  // Screen sizes array
  screenSizesList = ['9"', '10"', '11"', '12"', '13"', '14"', '15"', '16"', '17"'];

  // RAM sizes array
  ramList = ['1GB', '2GB', '4GB', '8GB', '16GB', '32GB', '64GB', '128GB'];

  // Storage types array
  storageTypes = ['HDD', 'SSD', 'NVMe SSD', 'Hybrid Drive'];

  // Storage sizes array
  storageSizes = ['128GB', '256GB', '512GB', '1TB', '2TB', '4TB', '8TB'];

  // functional conditions array
  functionalConditionsList = ['OUI', 'NON'];

  // aesthetic conditions array
  aestheticConditionsList = ['Excellent Etat', 'Bon état', 'Etat correct', 'Endommagé'];

  // Battery condition array
  batteryConditionsList: string[] = ['Supérieur à 1h30', 'Inférieure à 1h30', 'Hors service', 'Sans batterie'];

  // Aesthetic screen array
  screenConditionsList: string[] = ['Excellent état', 'Bon état', 'Endommagé', 'Etat correct', 'Je ne sais pas'];

  // Charger condition  array
  chargerConditionsList: string[] = ['Bon état', 'Endommagé', 'Pas de chargeur'];

  // Charger type  array
  chargerTypeList: string[] = ['Adaptable', 'Original'];

  // Keyboard layout array
  keyboardLayoutList: string[] = ['AZERTY', 'NON AZERTY', 'Je ne sais pas'];

  //Keyboard type array
  keyboardBacklightList: string[] = ['OUI', 'NON'];

  saveOffer() {
    let errorMessage = '';

    if (!this.selectedBrand) {
      errorMessage = 'Veuillez sélectionner une marque.';
    } else if (!this.isFunctional) {
      errorMessage = 'Veuillez spécifier si l’appareil est fonctionnel.';
    } else if (!this.aestheticCondition) {
      errorMessage = 'Veuillez indiquer l’état du PC.';
    } else if (!this.screenCondition) {
      errorMessage = 'Veuillez indiquer l’état de l’écran.';
    }

    if (errorMessage !== '') {
      this.toastr.error(errorMessage, 'Action requise !', {
        timeOut: 5000, // Dismiss after 5 seconds
        progressBar: true,
        progressAnimation: 'increasing',
        closeButton: true,
      });
      return;
    }

    const loggedUser = localStorage.getItem('id');
    // Assuming 'user_id' is the field name expected by your backend for the user ID
    const formData = new FormData();

    // Append all image files to 'formData' under the 'files' key
    for (let img of this.myFiles) {
      formData.append('files', img);
    }

    // Set other form fields
    formData.set("user_id", loggedUser ? loggedUser : ''); // Fallback to an empty string if 'loggedUser' is null
    formData.set("brand", this.selectedBrand);
    formData.set("model", this.selectedModel);
    formData.set("cpu", this.selectedCpu);
    formData.set("cpu_gen", this.selectedCpuGen);
    formData.set("gpu", this.selectedGPU);
    formData.set("monitor_size", this.screenSize);
    formData.set("ram", this.selectedRam);
    formData.set("storage_type", this.selectedStorageType);
    formData.set("storage_size", this.selectedStorageSize);
    formData.set("functional", this.isFunctional);
    formData.set("pc_status", this.aestheticCondition);
    formData.set("monitor_status", this.screenCondition);
    formData.set("battery_status", this.batteryCondition);
    formData.set("charger_status", this.chargerCondition);
    formData.set("charger_type", this.chargerType);
    formData.set("keyboard_type", this.keyboardLayout);
    formData.set("keyboard_retro", this.keyboardBacklight);
    formData.set("price", this.desiredPrice);
    formData.set("email", this.email);
    formData.set("phone", this.phone);
    // Set the type to "laptop"
    formData.set("type", "laptop"); // Add this line

    // Call the API through your offer service
    this.offerService.addOffer(formData).subscribe(
      (res) => {
        this.toastr.success('Demande crée avec succès');
        // Reset form or navigate away upon successful submission
        this.router.navigate(['/myrequests']);
        // Additional UI logic or routing as needed
      },
      (err) => {
        console.error(err);
        // Handle errors (e.g., display error messages)
      }
    );
  }


}  