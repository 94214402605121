<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">LISTE DES BONUS</h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-sm-6 col-lg-4" *ngFor="let bonus of listbonus">
                        <div class="card">
                            <img [src]="bonus.image" class="card-img-top" alt="...">
                            <div class="card-body">
                                <h5 class="card-title"><a href="javascript:void(0);"
                                        class="text-success stretched-link">{{bonus.type}}</a></h5>
                                <p class="card-text">
                                    {{bonus.description}}.
                                </p>
                                <div style="display: flex; justify-content: center; align-items: center;">
                                    <a href="javascript:void(0);"
                                        class="btn btn-primary mt-2 stretched-link">{{bonus.value}} dt</a>
                                </div>
                            </div> <!-- end card-body -->
                        </div> <!-- end card -->
                    </div> <!-- end col-->
                </div>

            </div> <!-- container -->

        </div> <!-- content -->

        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->