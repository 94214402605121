import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfferService } from 'src/app/Shared/Services/offer.service';
import { ValuationService } from 'src/app/Shared/Services/valuation.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-myoffers',
  templateUrl: './myoffers.component.html',
  styleUrls: ['./myoffers.component.css']
})

export class MyoffersComponent {
  @ViewChild('closeModalButton') closeModalButton: ElementRef | any;
  @ViewChild('closebutton2') closebutton2: any;
  @ViewChild('close') close: any;
  @ViewChild('closebutton') closebutton: ElementRef | any;
  offers: any[] = [];
  userId: string = '';
  status: string = "true"; // or "false", depending on the status you want to fetch
  p: number = 1;
  formulaire: FormGroup;
  LivraisonTypes = ["Enlèvement à domicile", "Dépôt au magasin"];
  public boutique: string[] = ['Scoop Les Berges du LAC', 'Scoop La Soukra', 'Scoop Centre urbain nord', 'Scoop Sousse'];
  selectedType = "Enlèvement à domicile";
  currentGivenPrice: string = '';
  sug_price: any;
  adresse: string | undefined;
  isModalOpen: boolean = false;
  searchQuery: string = '';

  constructor(private offerService: OfferService,
    private valuationService: ValuationService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router) {

    this.formulaire = this.fb.group({
      livraison: ['', Validators.required],
      boutique: [''],
      adresse: [''],
      phone: [''],
      nom: [''],
      email: ['']
    });

  }
  ngOnInit(): void {
    this.userId = localStorage.getItem('id') || ''; // Utilisez une chaîne vide comme valeur par défaut
    this.loadAndPrepareOffers();
  }

  SendThisVal(valuationArray: any[]): void {
    // Vérifiez si le tableau valuation est défini et contient au moins un élément
    if (valuationArray && valuationArray.length > 0) {
      const valuationId = valuationArray[0]._id;
      // Continuez avec votre logique en utilisant valuationId
      localStorage.setItem('val_id', valuationId);
      // Plus de logique ici...
    } else {
      console.error('Valuation est vide ou non défini');
      // Gérez le cas où valuation n'est pas disponible
    }
  }


  loadAndPrepareOffers(): void {
    this.offerService.getOfferWithValuation(this.userId, this.status).subscribe(
      data => {
        if (Array.isArray(data)) {
          this.offers = data.map((offer: any) => ({
            ...offer.offre,
            valuations: offer.valuation,
            valuationStatus: this.determineValuationStatus(offer)
          }));
          // Apply search filter
          this.filterOffers();
        }
      },
      error => {
        if (error.error && error.error.error === "No valuations found") {
          console.log("No valuations found for the user ID:", this.userId);
          // Handle this scenario in your component
        } else {
          console.log("Error fetching offers:", error);
        }
      }
    );
  }




  filterOffers(): void {
    if (this.searchQuery.trim() !== '') {
      this.offers = this.offers.filter(offer =>
        offer.brand.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        offer.model.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        offer.date.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }

  search(): void {
    this.loadAndPrepareOffers();
  }

  determineValuationStatus(offer: any): string {
    // Safety check for the existence of the valuation array and its first item
    if (!offer.valuation || offer.valuation.length === 0) return 'not_evaluated';

    const responseStatus = offer.valuation[0].response?.status ?? '';
    const adminResponseStatus = offer.valuation[0].response_admin?.status ?? '';

    // Check for 'Accepted' by admin
    if (adminResponseStatus === 'Accepted') {
      return 'accepted_admin';
    }

    // Check for provisional acceptance
    if (responseStatus === 'Accepted' && adminResponseStatus === 'pending') {
      return 'accepted_provisoire';
    }

    // Check for rejection by admin
    if (adminResponseStatus === 'Rejected') {
      return 'rejected_admin';
    }

    // Check for rejection by user or provisional rejection
    if (responseStatus === 'Rejected' && adminResponseStatus === 'pending') {
      return 'rejected_pending';
    }

    // Check for 'in progress' status
    if (responseStatus === 'pending') {
      return 'en_cour';
    }

    // Default or other logic
    return 'not_evaluated';
  }
  LivraisonTypeChanged() {
    const livraisonControl = this.formulaire.get('livraison');
    const adresseControl = this.formulaire.get('adresse');
    const phoneControl = this.formulaire.get('phone');
    const nomControl = this.formulaire.get('nom');
    const emailControl = this.formulaire.get('email');
    const boutiqueControl = this.formulaire.get('boutique');

    if (livraisonControl) {
      const livraisonType = livraisonControl.value;
      if (livraisonType === 'Enlèvement à domicile') {
        if (adresseControl) {
          adresseControl.setValidators([Validators.required]);
          adresseControl.updateValueAndValidity();
        }
        if (phoneControl) {
          phoneControl.setValidators([Validators.required]);
          phoneControl.updateValueAndValidity();
        }
        if (nomControl) {
          nomControl.clearValidators();
          nomControl.updateValueAndValidity();
        }
        if (emailControl) {
          emailControl.clearValidators();
          emailControl.updateValueAndValidity();
        }
      } else if (livraisonType === 'Dépôt au magasin') {
        if (boutiqueControl) {
          boutiqueControl.setValidators([Validators.required]);
          boutiqueControl.updateValueAndValidity();
        }
        if (nomControl) {
          nomControl.setValidators([Validators.required]);
          nomControl.updateValueAndValidity();
        }
        if (emailControl) {
          emailControl.setValidators([Validators.required, Validators.email]);
          emailControl.updateValueAndValidity();
        }
        if (adresseControl) {
          adresseControl.clearValidators();
          adresseControl.updateValueAndValidity();
        }
        if (phoneControl) {
          phoneControl.clearValidators();
          phoneControl.updateValueAndValidity();
        }
      }
    }
  }
  isDepotAuMagasin() {
    const livraisonControl = this.formulaire.get('livraison');
    return livraisonControl ? livraisonControl.value === 'Dépôt au magasin' : false;

  }

  isEnlevementADomicile() {
    const livraisonControl = this.formulaire.get('livraison');
    return livraisonControl ? livraisonControl.value === 'Enlèvement à domicile' : false;
  }

  selectBoutique(type: any) {
    this.adresse = type.target.value
  }

  SaveDilevery() {
    // Ensure we get the correct form value for 'adresse' whether it's for home pickup or store deposit.
    const livraisonType = this.formulaire.get('livraison')?.value;
    let adresse = this.formulaire.get('adresse')?.value;
    if (livraisonType === 'Dépôt au magasin' && this.adresse) {
      adresse = this.adresse; // Use the selected store address for 'Dépôt au magasin'
    }

    const response = {
      val_id: localStorage.getItem('val_id'),
      status: 'Accepted',
      method: livraisonType === 'Enlèvement à domicile' ? "Pickup à domicile" : "Dépôt au magasin", // Correctly translate the delivery method
      address: adresse, // Use the correctly determined address
      phone: this.formulaire.get('phone')?.value
    };

    this.valuationService.rejectOffer(response).subscribe(res => {
      // Assuming you have logic here to handle the response...
      this.toastr.success('Delivery details saved successfully');
      this.loadAndPrepareOffers();
      localStorage.removeItem('val_id');
      this.closebutton2.nativeElement.click();
    }, error => {
      // Handle any errors here
      this.toastr.error('Failed to save delivery details');
    });
  }


  augmentation(id: any) {
    let response = {
      val_id: id,
      status: 'augmentation',
    };
    this.valuationService.rejectOffer(response).subscribe((res) => {
      // Assuming loadAndPrepareOffers is a method that loads and prepares offers
      this.loadAndPrepareOffers(); // Corrected function call with parentheses
      localStorage.removeItem('val_id');
      this.toastr.info('Demande envoyé avec succès');
      this.closebutton.nativeElement.click();
      this.router.navigate(['/myrequests']);
    });
  }

  RejectOffer() {
    let response = {
      val_id: localStorage.getItem('val_id'),
      status: 'Rejected',
    };
    this.offerService.rejectOffer(response).subscribe((res) => {

      this.LivraisonTypeChanged();
      this.loadAndPrepareOffers(); // Corrected function call with parentheses
      this.closeModalButton.nativeElement.click();
      localStorage.removeItem('val_id');

    });
  }


  redirectToDetails(id: string) {
    // Save the ID in local storage
    localStorage.setItem('offerId', id);

    // Route to '/details'
    this.router.navigate(['/details']);
  }

}