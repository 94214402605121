export class AppConfig {

  public static authUrl = 'https://backend.swapify.tn/auth';
  public static resellerUrl = 'https://backend.swapify.tn/reseller';
  public static offerUrl = 'https://backend.swapify.tn/offre';
  public static userUrl = 'https://backend.swapify.tn/user';
  public static valUrl = 'https://backend.swapify.tn/valuation';
  public static notUrl = 'https://backend.swapify.tn/notification';
  public static bonusUrl = 'https://backend.swapify.tn/bonus';
  public static statUrl = 'https://backend.swapify.tn/statistics';
  public static specsUrl = 'https://backend.swapify.tn/specs';
  public static imgUrl = 'https://backend.swapify.tn/api/uploads';
  public static imgBonussUrl = 'https://backend.swapify.tn/api/bonus';
  public static subUrl = 'https://backend.swapify.tn/subscribers';

}
