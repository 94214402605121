<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title"> Vendre votre laptop </h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->


                <!-- product-wizard.component.html -->
                <div class="card">
                    <div class="card-body">

                        <div class="row" style="margin-top: 100px;">
                            <form (ngSubmit)="saveOffer()">
                                <div id="basicwizard">

                                    <ul class="nav nav-pills nav-justified form-wizard-header mb-4" role="tablist"
                                        style="display: none;">
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab1" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Account</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab2" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab3" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab4" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab5" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab6" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab7" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab8" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab9" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab10" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab11" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab12" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab13" data-bs-toggle="tab" data-toggle="tab">
                                                <span class="d-none d-sm-inline">Step</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a href="#tab14" data-bs-toggle="tab" data-toggle="tab"
                                                class="nav-link rounded-0 py-2 active" aria-selected="true" role="tab">
                                                <span class="d-none d-sm-inline">Finish</span>
                                            </a>
                                        </li>
                                    </ul>

                                    <div class="tab-content b-0 mb-0">
                                        <div class="tab-pane" id="tab1" role="tabpanel">
                                            <div class="row">
                                                <div class="tab-pane active show" id="account-2" role="tabpanel">
                                                    <div class="row text-center active px-sm-2 px-md-2">
                                                        <h4>
                                                            Quelle est la marque de votre laptop ?*
                                                        </h4>
                                                        <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                            <table class="table" id="myTable">
                                                                <tbody>
                                                                    <tr *ngFor="let brand of brandsList"
                                                                        (click)="updateSelectedBrand(brand)"
                                                                        [class.highlight]="brand === selectedBrand">
                                                                        <td>{{ brand }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul class="list-inline wizard mb-0">
                                                <li class="next list-inline-item float-end disabled">
                                                    <a href="javascript:void(0);" class="btn btn-dark">Suivant
                                                        <i class="mdi mdi-arrow-right ms-1"></i></a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="tab-pane" id="tab2" role="tabpanel">
                                            <div class="row text-center">
                                                <div class="col-12">
                                                    <div class="mb-3">
                                                        <label for="selectedModel" class="form-label">Modèle</label>
                                                        <input class="form-control" type="text"
                                                            [(ngModel)]="selectedModel" name="selectedModel"
                                                            placeholder="Ex : Acer Aspire A715-71G-57JW"
                                                            id="selectedModel">
                                                    </div>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i>Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="tab-pane" id="tab3" role="tabpanel">
                                            <div class="row">
                                                <div class="row g-2">
                                                    <div class="col-6">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="cpuSelect">Processeur</label>
                                                            <select *ngIf="!showCustomCpu" class="form-select"
                                                                (change)="updateCPU($event)" name="cpu" id="cpuSelect">
                                                                <option>Optionnel</option>
                                                                <option *ngFor="let cpu of cpuList" [value]="cpu">{{ cpu
                                                                    }}</option>
                                                                <option value="Autre option">Autre option</option>
                                                            </select>
                                                            <!-- Custom input for entering CPU appears only if 'Autre option' is selected -->
                                                            <input *ngIf="showCustomCpu" class="form-control"
                                                                type="text" [(ngModel)]="selectedCpu" name="customCpu"
                                                                placeholder="Entrer le processeur" />
                                                        </div>
                                                    </div>

                                                    <div class="col-6">
                                                        <div class="mb-3">
                                                            <label class="form-label"
                                                                for="cpuGenSelect">Generation</label>
                                                            <select *ngIf="!showCustomCpuGen" class="form-select"
                                                                (change)="updateCPUGeneration($event)" name="cpuGen"
                                                                id="cpuGenSelect">
                                                                <option>Optionnel</option>
                                                                <option *ngFor="let gen of cpuGenerationList"
                                                                    [value]="gen">{{ gen }}</option>
                                                                <option value="Autre option">Autre option</option>
                                                            </select>
                                                            <!-- Custom input for entering CPU generation appears only if 'Autre option' is selected -->
                                                            <input *ngIf="showCustomCpuGen" class="form-control"
                                                                type="text" [(ngModel)]="selectedCpuGen"
                                                                name="customCpuGen"
                                                                placeholder="Entrer la génération du processeur" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="row g-2">
                                                    <div class="col-6">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="gpuSelect">Carte
                                                                graphique</label>
                                                            <select *ngIf="!showCustomGPU" class="form-select"
                                                                (change)="updateGPU($event)" name="gpu" id="gpuSelect">
                                                                <option>Optionnel</option>
                                                                <option *ngFor="let gpu of gpuList" [value]="gpu">{{ gpu
                                                                    }}</option>
                                                                <option value="Autre option">Autre option</option>
                                                            </select>
                                                            <!-- Custom input for entering GPU appears only if 'Autre option' is selected -->
                                                            <input *ngIf="showCustomGPU" class="form-control"
                                                                type="text" [(ngModel)]="selectedGPU" name="customGPU"
                                                                placeholder="Entrer une carte graphique" />
                                                        </div>
                                                    </div>

                                                    <div class="col-6">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="screenSizeSelect">Taille
                                                                écran</label>
                                                            <select *ngIf="!showCustomScreenSize" class="form-select"
                                                                (change)="updateScreenSize($event)" name="screenSize"
                                                                id="screenSizeSelect">
                                                                <option>Optionnel</option>
                                                                <option *ngFor="let ecran of screenSizesList"
                                                                    [value]="ecran">{{
                                                                    ecran }}</option>
                                                                <option value="Autre option">Autre option</option>
                                                            </select>
                                                            <!-- Custom input for entering screen size appears only if 'Autre option' is selected -->
                                                            <input *ngIf="showCustomScreenSize" class="form-control"
                                                                type="text" [(ngModel)]="screenSize"
                                                                name="customScreenSize"
                                                                placeholder="Entrer la taille de l'écran" />
                                                        </div>
                                                    </div>

                                                    <div class="col-6">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="ramSelect">RAM</label>
                                                            <select class="form-select" name="ram" id="ramSelect"
                                                                (change)="updateSelectedRAM($event)">
                                                                <option value="">Optionnel</option>
                                                                <option *ngFor="let ram of ramList">{{ ram }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <!-- Storage Type -->
                                                    <div class="col-6">
                                                        <div class="mb-3">
                                                            <label class="form-label"
                                                                for="storageTypeSelect">Stockage</label>
                                                            <select class="form-select" name="storageType"
                                                                id="storageTypeSelect"
                                                                (change)="updateSelectedStorageType($event)">
                                                                <option value="">Optionnel</option>
                                                                <option *ngFor="let storage of storageTypes">{{ storage
                                                                    }}</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <!-- Storage Size -->
                                                    <div class="col-6">
                                                        <div class="mb-3">
                                                            <label class="form-label" for="storageSizeSelect">Taille
                                                                stockage total</label>
                                                            <select class="form-select" name="storageSize"
                                                                id="storageSizeSelect"
                                                                (change)="updateSelectedStorageSize($event)">
                                                                <option value="">Optionnel</option>
                                                                <option *ngFor="let size of storageSizes">{{ size }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i> Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane" id="tab4" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Votre ordinateur est-il fonctionnel ?*
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table" id="myTable">
                                                        <tbody>
                                                            <tr *ngFor="let condition of functionalConditionsList"
                                                                (click)="updateSelectedFunctionality(condition)"
                                                                [class.highlight]="condition === isFunctional">
                                                                <td>{{ condition }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i>Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="tab-pane" id="tab5" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Quel est l’état esthétique de votre ordinateur ?*
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table" id="myTable">
                                                        <tbody>
                                                            <tr *ngFor="let condition of aestheticConditionsList"
                                                                (click)="updateSelectedAestheticCondition(condition)"
                                                                [class.highlight]="condition === aestheticCondition">
                                                                <td>{{ condition }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i> Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane" id="tab6" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Quel est l’état de votre écran ?*
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table" id="myTable">
                                                        <tbody>
                                                            <tr *ngFor="let condition of screenConditionsList"
                                                                (click)="updateScreenCondition(condition)"
                                                                [class.highlight]="condition === screenCondition">
                                                                <td>{{ condition }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i> Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane" id="tab7" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Dans quel état est votre batterie ?
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table" id="myTable">
                                                        <tbody>
                                                            <tr *ngFor="let condition of batteryConditionsList"
                                                                (click)="updateBatteryCondition(condition)"
                                                                [class.highlight]="condition === batteryCondition">
                                                                <td>{{ condition }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i> Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane" id="tab8" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Dans quel état est votre chargeur ?
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table" id="myTable">
                                                        <tbody>
                                                            <tr *ngFor="let condition of chargerConditionsList"
                                                                (click)="updateChargerCondition(condition)"
                                                                [class.highlight]="condition === chargerCondition">
                                                                <td>{{ condition }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i> Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane" id="tab9" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Quel est le type de votre chargeur ?
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table" id="myTable">
                                                        <tbody>
                                                            <tr *ngFor="let type of chargerTypeList"
                                                                (click)="updateChargerType(type)"
                                                                [class.highlight]="type === chargerType">
                                                                <td>{{ type }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i> Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane" id="tab10" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Votre clavier est en ?
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table" id="myTable">
                                                        <tbody>
                                                            <tr *ngFor="let layout of keyboardLayoutList"
                                                                (click)="updateKeyboardLayout(layout)"
                                                                [class.highlight]="layout === keyboardLayout">
                                                                <td>{{ layout }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i> Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane" id="tab11" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Votre clavier est-elle rétro-éclairée ?
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table" id="myTable">
                                                        <tbody>
                                                            <tr *ngFor="let backlight of keyboardBacklightList"
                                                                (click)="updateKeyboardBacklight(backlight)"
                                                                [class.highlight]="backlight === keyboardBacklight">
                                                                <td>{{ backlight }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i> Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant <i
                                                            class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane" id="tab12" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Importer une photo récente de votre écran
                                                </h4>
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <div class="container mt-5">
                                                        <form id="img-upload-form" enctype="multipart/form-data"
                                                            class="form-input">
                                                            <div class="mb-3">
                                                                <input type="file" class="form-control" id="upload_imgs"
                                                                    name="pc_photo" (change)="onFileSelected($event)"
                                                                    accept="image/*" multiple>
                                                            </div>
                                                            <!-- Display selected images -->
                                                            <div id="img_preview" class="row">
                                                                <div class="col-3 mb-3"
                                                                    *ngFor="let preview of imagePreviews; let i = index">
                                                                    <img [src]="preview" alt="Selected Image"
                                                                        class="img-thumbnail">
                                                                    <button class="btn btn-danger btn-sm mt-1"
                                                                        (click)="removeImage(i)">Remove</button>
                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i>Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">Suivant
                                                        <i class="mdi mdi-arrow-right ms-1"></i></button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="tab-pane" id="tab13" role="tabpanel">
                                            <div class="row text-center">
                                                <h4 for="bootstrap-wizard-wizard-email">
                                                    Dernière ligne droite avant l’estimation :
                                                </h4>
                                                <div class="mb-3">
                                                    <label for="desiredPrice" class="form-label">Prix de vente souhaité
                                                        (option)</label>
                                                    <input type="text" class="form-control" placeholder="prix en TND"
                                                        id="desiredPrice" name="desiredPrice"
                                                        [(ngModel)]="desiredPrice">
                                                </div>
                                                <div class="mb-3">
                                                    <textarea class="form-control" rows="5"
                                                        placeholder="Dites-nous-en un maximum sur votre appareil ... (option)"
                                                        id="additionalInfo" name="additionalInfo"
                                                        [(ngModel)]="additionalInfo"></textarea>
                                                </div>
                                                <div class="mb-3">
                                                    <input class="form-control" type="email" id="email"
                                                        placeholder="Email address" required [(ngModel)]="email"
                                                        name="email">
                                                </div>
                                                <div class="mb-3">
                                                    <input class="form-control" type="text" id="phone"
                                                        placeholder="Phone" [(ngModel)]="phone" name="phone">
                                                </div>
                                            </div>


                                            <ul class="pager wizard mb-0 list-inline">
                                                <li class="previous list-inline-item">
                                                    <button type="button" class="btn btn-light"><i
                                                            class="mdi mdi-arrow-left me-1"></i>Précédent</button>
                                                </li>
                                                <li class="next list-inline-item float-end disabled">
                                                    <button type="button" class="btn btn-dark">
                                                        Suivant <i class="mdi mdi-arrow-right ms-1"></i>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="tab-pane" id="tab14" role="tabpanel">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h3 class="text-center mb-4">Vos Options Sélectionnées</h3>
                                                </div>
                                            </div>
                                            <!-- Afficher les valeurs sélectionnées dans un tableau si offerData existe -->
                                            <div class="row" *ngIf="myForm">
                                                <div class="col-12">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Marque :</td>
                                                                <td>{{selectedBrand}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Modèle :</td>
                                                                <td>{{selectedModel}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Processeur :</td>
                                                                <td>{{selectedCpu}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Génération du processeur :</td>
                                                                <td>{{selectedCpuGen}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Carte graphique :</td>
                                                                <td>{{selectedGPU}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Taille de l'écran :</td>
                                                                <td>{{screenSize}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Mémoire RAM :</td>
                                                                <td>{{selectedRam}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Type de stockage :</td>
                                                                <td>{{selectedStorageType}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Taille du stockage :</td>
                                                                <td>{{selectedStorageSize}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>État fonctionnel :</td>
                                                                <td>{{isFunctional}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>État esthétique :</td>
                                                                <td>{{aestheticCondition}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>État de l'écran :</td>
                                                                <td>{{screenCondition}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>État de la batterie :</td>
                                                                <td>{{batteryCondition}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>État du chargeur :</td>
                                                                <td>{{chargerCondition}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Type de chargeur :</td>
                                                                <td>{{chargerType}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Disposition du clavier :</td>
                                                                <td>{{keyboardLayout}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Clavier rétro-éclairé :</td>
                                                                <td>{{keyboardBacklight}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Prix souhaité :</td>
                                                                <td>{{desiredPrice}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Informations supplémentaires :</td>
                                                                <td>{{additionalInfo}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Email :</td>
                                                                <td>{{email}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Téléphone :</td>
                                                                <td>{{phone}}</td>
                                                            </tr>
                                                            <tr *ngFor="let image of imagePreviews">
                                                                <td>Image:</td>
                                                                <td><img [src]="image" alt="Selected Image"
                                                                        style="max-width: 200px;"></td>
                                                            </tr>
                                                            <!-- Ajouter plus de lignes pour d'autres propriétés au besoin -->
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <p class="text-center">En cliquant sur "Obtenir mon offre", vous
                                                        acceptez votre sélection des détails de votre laptop.
                                                    </p>
                                                    <p class="text-center">En cliquant sur "Obtenir mon offre", vous
                                                        acceptez également les Termes et Conditions.</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <ul class="pager wizard mb-0 list-inline mt-1">
                                                        <li class="previous list-inline-item">
                                                            <button type="button" class="btn btn-light"><i
                                                                    class="mdi mdi-arrow-left me-1"></i>Précédent</button>
                                                        </li>
                                                        <li class="next list-inline-item float-end disabled">
                                                            <button type="submit" class="btn btn-dark">Obtenir mon
                                                                offre</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>





            </div> <!-- container -->

        </div> <!-- content -->

        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->