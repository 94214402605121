<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">LISTE DES DEMANDE EN ATTENTE </h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-6">
                                        <select class="form-select" id="example-select">
                                            <option>Tous les équipements</option>
                                            <option>Écran</option>
                                            <option>Laptop</option>
                                            <option>Ordinateur de bureau</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Chercher..."
                                                [(ngModel)]="searchTerm">
                                            <button class="btn btn-dark" type="button"
                                                (click)="filterValuationsBySearchTerm()">Chercher</button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th>Réference</th>
                                            <th>Utilisateur</th>
                                            <th>Informations</th>
                                            <th>Équipement</th>
                                            <th>Processeur</th>
                                            <th>Génération</th>
                                            <th>Date</th>
                                            <th>Etat</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr
                                            *ngFor="let offer of offers| paginate: { itemsPerPage: 5,currentPage: p }; let i = index">
                                            <td>
                                                <a (click)="redirectToDetails(offer._id)"
                                                    href="javascript:void(0)">PC#{{offer._id.substring(18,24)}}</a>
                                            </td>
                                            <td>{{offer.user_id.name}}</td>
                                            <td>{{offer.brand}}: {{offer.model}}</td>
                                            <td [ngSwitch]="offer.type">
                                                <span *ngSwitchCase="'monitor'" class="badge bg-warning">Écran</span>
                                                <span *ngSwitchCase="'desktop'" class="badge bg-info">Ordinateur de
                                                    bureau</span>
                                                <span *ngSwitchDefault class="badge bg-primary">Laptop</span>
                                            </td>                                            <td>{{offer.cpu}}</td>
                                            <td>{{offer.cpu_gen}}</td>
                                            <td>{{offer.date}}</td>
                                            <td>
                                                <div *ngIf="offer.evaluated === false">
                                                    <i class="mdi mdi-circle text-info"></i>En cours
                                                </div>
                                                <div *ngIf="offer.evaluated === true">
                                                    <i class="mdi mdi-circle text-success"></i>Evalue
                                                </div>
                                            <td class="table-action">
                                                <a type="button" data-bs-toggle="modal"
                                                    data-bs-target="#danger-alert-modal" class="action-icon"> <i
                                                        class="mdi mdi-delete"
                                                        (click)="prepareDelete(offer._id)"></i></a>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div
                                    style="display: flex; justify-content: center; align-items: center;margin-top: 20px;">
                                    <nav>
                                        <ul class="pagination pagination-rounded mb-0">
                                            <li class="page-item">
                                                <pagination-controls (pageChange)="p = $event"
                                                    class="pagination-controls"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div> <!-- end col -->
                </div>

            </div> <!-- container -->

        </div> <!-- content -->

        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->
    <div #deleteModal id="danger-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content modal-filled bg-danger">
                <div class="modal-body p-4">
                    <div class="text-center">
                        <i class="ri-close-circle-line h1"></i>
                        <h4 class="mt-2">Confirmer la suppression</h4>
                        <p class="mt-3">Êtes-vous sûr de vouloir supprimer cet élément ? Cette action est irréversible.
                        </p>
                        <button type="button" class="btn btn-light my-2" style="margin-right: 10px;"
                            (click)="confirmDelete()">Continuer</button>
                        <button type="button" class="btn btn-light my-2" data-bs-dismiss="modal">Annuler</button>
                    </div>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</div>
<!-- END wrapper -->