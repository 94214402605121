import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValuationService {

  constructor(private http: HttpClient) { }

  getByCustomer(user_id: any) {
    return this.http.get<any>(
      `${AppConfig.valUrl}/getValutionByCustomer/${user_id}`
    );
  }

  valOffer(val: any, id: any) {
    return this.http.post<any>(`${AppConfig.valUrl}/post/${id}`, val);
  }

  getByCustomerOffVall(user_id: any) {
    return this.http.get<any>(`${AppConfig.valUrl}/getOfferWithValuation/${user_id}`);
  }

  getByCustomerWithstatus(user_id: any, status: any) {
    return this.http.get<any>(
      `${AppConfig.valUrl}/getOfferWithValuation/${user_id}/${status}`
    );
  }

  rejectOffer(response: any) {
    return this.http.post<any>(
      `${AppConfig.valUrl}/respondeToValuation`, response
    );
  }

  getrespondedvaluation() {
    return this.http.get<any>(
      `${AppConfig.valUrl}/getRespondedValuations`
    );
  }

  getrespondedvaluationstat(status: string) {
    return this.http.get<any>(
      `${AppConfig.valUrl}/getRespondedValuations/${status}`
    );
  }

  finalAdminResponse(id: any, resp: any) {
    return this.http.post<any>(
      `${AppConfig.valUrl}/finalAdminResponse/${id}`, resp
    );
  }



}
