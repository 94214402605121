<!-- Begin page -->
<div class="wrapper">


    <!-- ========== Topbar Start ========== -->
    <app-header></app-header>
    <!-- ========== Topbar End ========== -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-sidebar></app-sidebar>
    <!-- ========== Left Sidebar End ========== -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
        <div class="content">

            <!-- Start Content-->
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">DETAILS DE L'OFFRE </h4>
                        </div>
                    </div>
                </div>
                <!-- end page title -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">


                                <div class="row">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-5">
                                                        <!-- Product image Carousel -->
                                                        <div id="carouselExampleControls" class="carousel slide"
                                                            data-bs-ride="carousel">
                                                            <div class="carousel-inner" role="listbox">
                                                                <!-- Utilisez *ngFor pour itérer sur les images du carrousel -->
                                                                <div class="carousel-item"
                                                                    *ngFor="let img of arrayImg; let isFirst = first"
                                                                    [class.active]="isFirst" [class.active]="isFirst">
                                                                    <img class="d-block img-fluid" [src]="img.image"
                                                                        alt="product">
                                                                </div>
                                                            </div>
                                                            <a class="carousel-control-prev"
                                                                href="#carouselExampleControls" role="button"
                                                                data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon"
                                                                    aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </a>
                                                            <a class="carousel-control-next"
                                                                href="#carouselExampleControls" role="button"
                                                                data-bs-slide="next">
                                                                <span class="carousel-control-next-icon"
                                                                    aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </a>
                                                        </div>


                                                    </div> <!-- end col -->
                                                    <div class="col-lg-7">
                                                        <form class="ps-lg-4">
                                                            <!-- Product title -->
                                                            <h3 class="mt-0" *ngIf="offer && offer._id">
                                                                PC#{{ offer._id.substring(18,24) }}<a
                                                                    href="javascript: void(0);" class="text-muted"><i
                                                                        class="mdi mdi-square-edit-outline ms-2"></i></a>
                                                                <br>
                                                                {{ offer.user_id && offer.user_id.name | titlecase
                                                                }}<br>
                                                                {{ offer.user_id && offer.user_id.phone | titlecase }}
                                                            </h3>
                                                            <p class="mb-1" *ngIf="offer && offer.date">Date d'ajout: {{
                                                                offer.date }}</p>
                                                            <!-- Product stock -->
                                                            <div class="mt-3">
                                                                <h4>
                                                                    <div class="mt-3"
                                                                        *ngIf="offer && offer.evaluated; else notEvaluated">
                                                                        <span class="badge badge-success-lighten">Déjà
                                                                            Évalué</span>
                                                                    </div>
                                                                    <ng-template #notEvaluated>
                                                                        <span class="badge badge-danger-lighten">Non
                                                                            Évalué</span>
                                                                    </ng-template>
                                                                </h4>
                                                            </div>

                                                            <!-- Product description -->
                                                            <div class="mt-4" *ngIf="offer && offer.price">
                                                                <h6 class="font-14">Prix suggéré:</h6>
                                                                <h3>{{ offer.price }}</h3>
                                                            </div>

                                                            <!-- Quantity -->
                                                            <div class="mt-4">
                                                                <button (click)="showPdf()" type="button"
                                                                    class="btn btn-danger ms-2"><i
                                                                        class="uil-file-download-alt"></i>
                                                                    Télécharger</button>
                                                                <button
                                                                    *ngIf="offer && !offer.evaluated && role == 'Admin'"
                                                                    class="btn btn-danger ms-2" data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"><i></i>
                                                                    Evaluer</button>
                                                                <button *ngIf="offer && !offer.evaluated"
                                                                    (click)="prepareDelete(offer._id)"
                                                                    class="btn btn-danger ms-2" data-bs-toggle="modal"
                                                                    data-bs-target="#danger-alert-modal"><span
                                                                        class="fas fa-trash-alt me-1"></span>Supprimer</button>
                                                            </div>

                                                            <!-- Product information -->
                                                            <div class="mt-4">
                                                                <div class="row">
                                                                    <div *ngIf="offer && offer.price">
                                                                        <h6 class="font-14">Prix suggéré:</h6>
                                                                        <p class="text-sm lh-150">{{ offer.price }}</p>
                                                                    </div>
                                                                    <div class="col-md-4"
                                                                        *ngIf="offer && offer.evaluated">
                                                                        <h6 class="font-14">prix proposé</h6>
                                                                        <p class="text-sm lh-150">{{ valuation &&
                                                                            valuation.given_price }} TND</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                    </div> <!-- end col -->
                                                </div> <!-- end row-->

                                                <div class="table-responsive mt-4">
                                                    <table class="table table-bordered table-centered mb-0">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th>Composant</th>
                                                                <th>Détails</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngIf="offer">
                                                                <td>Type</td>
                                                                <td>
                                                                    {{ offer.type === 'monitor' ? 'Écran' : (offer.type
                                                                    === 'desktop' ? 'Ordinateur de bureau' : 'Laptop')
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr *ngFor="let key of getPropertyKeys()">
                                                                <td>{{ getPropertyDisplayName(key) }}</td>
                                                                <td>{{ offer[key] }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <!-- end table-responsive-->


                                            </div> <!-- end card-body-->
                                        </div> <!-- end card-->
                                    </div> <!-- end col-->
                                </div>
                                <!-- end row-->

                            </div> <!-- end card-body-->
                        </div> <!-- end card-->
                    </div> <!-- end col -->
                </div>

            </div> <!-- container -->

        </div> <!-- content -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Evaluer cette demande</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="formulaire">
                            <div class="mb-3">
                                <label class="col-form-label">Prix selon evaluation:</label>
                                <input type="text" class="form-control" id="recipient-name"
                                    formControlName="given_price">
                            </div>
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">Commentaire:</label>
                                <textarea class="form-control" id="message-text" formControlName="comment"></textarea>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            #closebutton>Close</button>
                        <button type="button" class="btn btn-primary" (click)="Evaluate()">Enregister</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="danger-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content modal-filled bg-danger">
                    <div class="modal-body p-4">
                        <div class="text-center">
                            <i class="ri-close-circle-line h1"></i>
                            <h4 class="mt-2">Confirmer la suppression</h4>
                            <p class="mt-3">Êtes-vous sûr de vouloir supprimer cet élément ? Cette action est
                                irréversible.</p>
                            <button type="button" class="btn btn-light my-2" style="margin-right: 10px;"
                                (click)="confirmDelete()">Continuer</button>
                            <button type="button" class="btn btn-light my-2" data-bs-dismiss="modal"
                                #closebutton1>Annuler</button>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->