<div id="root">
    <main gridtemplatecolumns="50% 50%" data-testid="layout" class="hYsyuB">
        <div class="cjpdQ">
            <div class="bZzVws  fYucMZ">
                <div class="bZzVws  bTflzG">
                    <div class="bKYSlz">
                        <picture>
                            <source sizes="@media (max-width: 1279px) 100vw, 50vw" type="image/jpeg"><img
                                src="assets/images/background_gradient_xl.jpg" alt="background gradient">
                        </picture>
                    </div>
                    <div class="bZzVws  kDKymR">
                        <div class="bZzVws">
                            <div class="lbhavv">
                                <div class="dnOXYm"><a routerLink="/">
                                        <picture>
                                            <source type="image/webp"><img src="assets/static/Logo-Swapify-3.png"
                                                alt="Swapify logo">
                                        </picture>
                                    </a>
                                </div>
                            </div>
                            <div class="brctdI">
                                <div class="login-container">
                                    <form [formGroup]="Register" (ngSubmit)="saveUser()">
                                        <div class="galqqa">
                                            <h2 class="dAGLka">Créer un compte sur Swapify</h2>
                                            <!-- Prénom -->
                                            <div class="gwjbDQ">
                                                <div class="bYbEfh">
                                                    <div class="flwtlQ">
                                                        <div class="eJPFMs htXUem">
                                                            <label for="firstName" class="dffLbX ddEEZM">Prénom</label>
                                                            <input id="name" formControlName="name" type="text"
                                                                spellcheck="false" autocomplete="off" class="jnzpqg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Nom de famille -->
                                            <div class="gwjbDQ">
                                                <div class="bYbEfh">
                                                    <div class="flwtlQ">
                                                        <div class="eJPFMs htXUem">
                                                            <label for="lastname" class="dffLbX ddEEZM">Nom de
                                                                famille</label>
                                                            <input id="lastname" formControlName="lastname" type="text"
                                                                spellcheck="false" autocomplete="off" class="jnzpqg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Numéro de téléphone -->
                                            <div class="gwjbDQ">
                                                <div class="bYbEfh">
                                                    <div class="flwtlQ">
                                                        <div class="eJPFMs htXUem">
                                                            <label for="phone" class="dffLbX ddEEZM">Numéro de
                                                                téléphone</label>
                                                            <input id="phone" formControlName="phone" type="text"
                                                                spellcheck="false" autocomplete="off" class="jnzpqg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Date de naissance -->
                                            <div class="gwjbDQ">
                                                <div class="bYbEfh">
                                                    <div class="flwtlQ">
                                                        <div class="eJPFMs htXUem">
                                                            <label for="birthDay" class="dffLbX ddEEZM">Date de
                                                                naissance</label>
                                                            <input id="birthDay" formControlName="birth_day" type="date"
                                                                spellcheck="false" autocomplete="off" class="jnzpqg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Adresse e-mail -->
                                            <div class="gwjbDQ">
                                                <div class="bYbEfh">
                                                    <div class="flwtlQ">
                                                        <div class="eJPFMs htXUem">
                                                            <label for="email" class="dffLbX ddEEZM">Adresse
                                                                e-mail</label>
                                                            <input id="email" formControlName="email" type="email"
                                                                spellcheck="false" autocomplete="off" class="jnzpqg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Mot de passe -->
                                            <div class="gwjbDQ">
                                                <div class="bYbEfh">
                                                    <div class="flwtlQ">
                                                        <div class="eJPFMs htXUem">
                                                            <label for="password" class="dffLbX ddEEZM">Mot de
                                                                passe</label>
                                                            <input id="password" formControlName="password"
                                                                type="password" spellcheck="false" autocomplete="off"
                                                                class="jnzpqg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Répéter le mot de passe -->
                                            <div class="gwjbDQ">
                                                <div class="bYbEfh">
                                                    <div class="flwtlQ">
                                                        <div class="eJPFMs htXUem">
                                                            <label for="passwordConfirm" class="dffLbX ddEEZM">Répéter
                                                                le mot de passe</label>
                                                            <input id="passwordConfirm"
                                                                formControlName="passwordConfirm" type="password"
                                                                spellcheck="false" autocomplete="off" class="jnzpqg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a class="uLtjf" routerLink="/Login">Vous avez un compte? Se connecter!</a>
                                            <!-- Submit Button -->
                                            <div class="jBgCDI">
                                                <button type="submit" aria-label="submit" class="jmeDk gWKcWZ">Créer un
                                                    compte</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div data-testid="layout-right-column" class="kXfLYH">
            <div class="bKYSlz cYvBZE">
                <picture>
                    <source sizes="@media (max-width: 1279px) 100vw, 50vw" type="image/jpeg"><img
                        src="assets/images/DALL.webp" alt="laptop">
                </picture>
            </div>
        </div>
    </main>
</div>